import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { map, switchMap, mergeMap, catchError } from 'rxjs/operators';
import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { stringify } from 'query-string';
import { of } from 'rxjs/internal/observable/of';
import { RichContentActions, RichContentActionTypes, Content, RichContent } from './types';
import {
	GetRichContentListFailure,
	GetRichContentListSuccess,
	ContentActionFailure,
	ContentActionSuccess
} from './actions';

import { secureRequest } from '../../utils/functions';
import { APIError } from '../Login/actions';
import { RootState } from '../../reducers';

const {
	REACT_APP_API_URL = '',
	REACT_APP_CONTENT_BASE_URL = '',
	REACT_APP_CONTENT_PRIVATE_BASE_URL = '',
	REACT_APP_CONTENT_API = ''
} = process.env;
const CONTENT_API = REACT_APP_API_URL + REACT_APP_CONTENT_BASE_URL + REACT_APP_CONTENT_API;
const PRIVATE_CONTENT_API =
	REACT_APP_API_URL + REACT_APP_CONTENT_PRIVATE_BASE_URL + REACT_APP_CONTENT_API;
/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
export const GetContentEpic = (
	action$: ActionsObservable<RichContentActions>,
	state$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(RichContentActionTypes.GETRICHCONTENTLIST),
		switchMap((action) => {
			return secureRequest(
				{
					method: 'GET',
					url: `${PRIVATE_CONTENT_API}?${stringify(action.payload)}`,
					headers: {
						'Content-Type': 'application/json'
					},
					async: true,
					crossDomain: true
				},
				state$
			).pipe(
				map((response: AjaxResponse) => GetRichContentListSuccess(response, action)),
				catchError((error: AjaxError) =>
					of(GetRichContentListFailure(error, action), APIError(error, action))
				)
			);
		})
	);

/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
export const ContentActionEpic = (
	action$: ActionsObservable<RichContentActions>,
	section$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(RichContentActionTypes.RICHCONTENTACTION),
		mergeMap((action) => {
			const ActionPayload = action.payload as Content<RichContent>;
			return secureRequest(
				{
					method: 'PUT',
					url: CONTENT_API,
					body: JSON.stringify(
						Array.isArray(ActionPayload) ? ActionPayload : [ActionPayload]
					),
					headers: {
						'Content-Type': 'application/json'
					},
					timeout: 30000,
					async: true,
					crossDomain: true
				},
				section$
			).pipe(
				map((response: AjaxResponse) => ContentActionSuccess(response, action)),
				catchError((error: AjaxError) =>
					of(ContentActionFailure(error, action), APIError(error, action))
				)
			);
		})
	);
