import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import {
	GetRichContentPayload,
	GetRichContentListAction,
	GetRichContentListFailureAction,
	GetRichContentListSuccessAction,
	RichContentActionTypes,
	ContentAction,
	RichContentAction,
	RichContentActionSuccess,
	RichContentActionFailure,
	AddContentAction,
	CloseContentModalAction,
	Content,
	RichContent,
	EditContentAction,
	EditContentInfoPayload,
	EditContentInfoAction
} from './types';

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetRichContentList = (
	payload: GetRichContentPayload,
	meta?: any
): GetRichContentListAction => {
	return {
		type: RichContentActionTypes.GETRICHCONTENTLIST,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetRichContentListSuccess = (
	payload: AjaxResponse,
	meta?: any
): GetRichContentListSuccessAction => {
	return {
		type: RichContentActionTypes.GETRICHCONTENTLISTSUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetRichContentListFailure = (
	payload: AjaxError,
	meta?: any
): GetRichContentListFailureAction => {
	return {
		type: RichContentActionTypes.GETRICHCONTENTLISTFAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const PerformContentAction: ContentAction = (payload: any, meta: any): RichContentAction => {
	return {
		type: RichContentActionTypes.RICHCONTENTACTION,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const ContentActionSuccess = (
	payload: AjaxResponse,
	meta?: any
): RichContentActionSuccess => {
	return {
		type: RichContentActionTypes.RICHCONTENTACTIONSUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const ContentActionFailure = (payload: AjaxError, meta?: any): RichContentActionFailure => {
	return {
		type: RichContentActionTypes.RICHCONTENTACTIONFAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const AddContent = (payload?: any, meta?: any): AddContentAction => {
	return {
		type: RichContentActionTypes.ADDRICHCONTENT,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const EditContent = (payload: Content<RichContent>, meta?: any): EditContentAction => {
	return {
		type: RichContentActionTypes.EDITRICHCONTENT,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const EditContentInfo = (
	payload: EditContentInfoPayload,
	meta?: any
): EditContentInfoAction => {
	return {
		type: RichContentActionTypes.EDITRITCHCONTENTINFO,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const CloseContentModal = (payload?: any, meta?: any): CloseContentModalAction => {
	return {
		type: RichContentActionTypes.CLOSERITCHCONTENTMODAL,
		payload,
		meta
	};
};
