import { AjaxRequest, ajax } from 'rxjs/ajax';
import { StateObservable } from 'redux-observable';
import omit from 'lodash/omit';
import { REGEX_EMAIL, REGEX_NUMBER } from '../constants/variables';
import { RootState } from '../reducers';

export const isPhoneNumber = (value: string) => {
	if (!value) {
		return false;
	}
	return REGEX_NUMBER.test(value.toLowerCase());
};

export const isEmail = (value: string) => {
	if (!value) {
		return false;
	}
	return REGEX_EMAIL.test(value.toLowerCase());
};

export const secureRequest = (options: AjaxRequest, store: StateObservable<RootState>) => {
	const requestSettings = omit(options, 'withCredentials', 'timeout');
	const headers: any = { ...requestSettings.headers };
	headers.Authorization = `${store.value.auth.user.token}`;
	return ajax({ ...requestSettings, headers });
};
export const fileToUrl = (file: File) => URL.createObjectURL(file);
