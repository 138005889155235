import { AjaxError } from 'rxjs/ajax';
import { ActionState } from '../../constants/api';
import CreateAsyncAction from '../../utils/actions';

export interface UploadPercentPayload {
	percent: number;
}
export interface FileProgress {
	id: string;
	uploadFile: ActionState;
	url: string;
	percent: number;
	errorData: Partial<AjaxError>;
}
export interface GetFiles {
	id: string;
	url: string;
	getFile: ActionState;
	errorData: Partial<AjaxError>;
	[name: string]: any;
}
export interface FileState {
	files: Record<string, FileProgress>;
	getFiles: Record<string, GetFiles>;
	uploadFile: ActionState;
	getFile: ActionState;
}

export interface FileUpload {
	id: string;
	file: File;
}

export interface GETFILE {
	contentId: string;
	fileId: string;
	fileName: string;
	fileType?: string;
}
export const FILE_UPLOAD = CreateAsyncAction<FileUpload>('FILE_UPLOAD');
export const GET_FILE = CreateAsyncAction<GETFILE>('GET_FILE');
export interface FileActions {
	type: string;
	payload?: any;
	meta?: any;
}
