import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import set from 'lodash/set';
import find from 'lodash/find';
import get from 'lodash/get';
import clone from 'lodash/cloneDeep';
import nth from 'lodash/nth';
import isEmpty from 'lodash/isEmpty';
import {
	CategoryInfo,
	SectionContent,
	SectionState,
	SectionActions,
	SectionActionTypes,
	Selection,
	FileInfo,
	SubCategoryInfo,
	SubMenuInfo,
	AddSectionPayload,
	EditSectionPayload,
	EditSectionInfoPayload,
	SelectedID
} from './types';
import { ActionState } from '../../constants/api';

const INITIAL_FILE: FileInfo = {
	fileId: '',
	fileName: '',
	fileSequence: 1,
	isPublished: false
};

const INITIAL_SUB_CATEGORY: SubCategoryInfo = {
	isPublished: false,
	sequence: 1,
	name: '',
	additionalData: {
		display: 'true'
	},
	files: []
};

const INITIAL_SUB_MENU: SubMenuInfo = {
	isPublished: false,
	name: '',
	pageTitle: '',
	sequence: 1,
	subCategories: []
};

const INITIAL_CATEGORY: CategoryInfo = {
	investorCategoryName: '',
	sequence: 0,
	isPublished: false,
	subMenus: []
};
const INITIAL_CONTENT: SectionContent = {
	placeholder: 'InvestorRelations_Details',
	contentTemplate: 'INVESTOR',
	page: 'Investor_Relationship',
	sequence: 0,
	isPublished: false,
	content: INITIAL_CATEGORY
};
const INITIAL_SELECTION: Selection = {
	category: '',
	file: '',
	subcategory: '',
	submenu: ''
};
const INITIAL_SELECTION_ID: SelectedID = {
	groupId: '',
	subMenuId: '',
	subCategoryId: '',
	fileId: ''
};
const INITIAL_STATE: SectionState = {
	getSectionList: ActionState.NONE,
	publishSection: ActionState.NONE,
	unpublishSection: ActionState.NONE,
	draftSection: ActionState.NONE,
	selected: INITIAL_SELECTION,
	sections: [],
	editSection: INITIAL_CONTENT,
	editSectionModal: false,
	editMode: 'add',
	editActionType: 'category',
	errorData: {},
	reorderSections: ActionState.NONE,
	selectedIds: INITIAL_SELECTION_ID
};
/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const InvestorRelationsReducer = (
	state: SectionState = INITIAL_STATE,
	action: SectionActions
): SectionState => {
	switch (action.type) {
		case SectionActionTypes.GET_INVESTORS_RELATIONS: {
			return {
				...state,
				getSectionList: ActionState.LOADING
			};
		}
		case SectionActionTypes.GET_INVESTORS_RELATIONS_SUCCESS: {
			const { response } = action.payload as AjaxResponse;
			return {
				...state,
				getSectionList: ActionState.SUCCESS,
				sections: response.sort((a, b) => {
					if (Number(a.content.sequence) === Number(b.content.sequence)) return 0;
					if (Number(a.content.sequence) > Number(b.content.sequence)) return 1;
					return -1;
				})
			};
		}
		case SectionActionTypes.GET_INVESTORS_RELATIONS_FAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				getSectionList: ActionState.FAILURE,
				errorData: response
			};
		}
		case SectionActionTypes.DRAFT_SECTION: {
			const mode = action.meta;
			return {
				...state,
				editMode: mode ? 'edit' : 'add',
				draftSection: ActionState.LOADING
			};
		}
		case SectionActionTypes.DRAFT_SECTION_SUCCESS: {
			return {
				...state,
				draftSection: ActionState.SUCCESS,
				editSection: {}
			};
		}
		case SectionActionTypes.DRAFT_SECTION_FAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				draftSection: ActionState.FAILURE,
				errorData: response
			};
		}
		case SectionActionTypes.PUBLISH_SECTION: {
			const { mode } = action.meta;
			const reordering = action.meta.reorder;
			return {
				...state,
				publishSection: ActionState.LOADING,
				editMode: mode ? 'edit' : 'add',
				reorderSections: reordering ? ActionState.LOADING : ActionState.NONE
			};
		}
		case SectionActionTypes.PUBLISH_SECTION_SUCCESS: {
			const reordering = action.meta.meta.reorder;
			return {
				...state,
				publishSection: ActionState.SUCCESS,
				editSection: {},
				reorderSections: reordering ? ActionState.SUCCESS : ActionState.NONE
			};
		}
		case SectionActionTypes.PUBLISH_SECTION_FAILURE: {
			const reordering = action.meta.meta.reorder;
			const response = action.payload as AjaxError;
			return {
				...state,
				publishSection: ActionState.FAILURE,
				errorData: response,
				reorderSections: reordering ? ActionState.FAILURE : ActionState.NONE
			};
		}
		case SectionActionTypes.UNPUBLISH_SECTION: {
			const mode = action.meta;
			return {
				...state,
				editMode: mode ? 'edit' : 'add',
				unpublishSection: ActionState.LOADING
			};
		}
		case SectionActionTypes.UNPUBLISH_SECTION_SUCCESS: {
			return {
				...state,
				unpublishSection: ActionState.SUCCESS,
				editSection: {}
			};
		}
		case SectionActionTypes.UNPUBLISH_SECTION_FAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				unpublishSection: ActionState.FAILURE,
				errorData: response
			};
		}
		case SectionActionTypes.CLOSE_EDIT_SECTION: {
			return {
				...state,
				editSectionModal: false
			};
		}
		case SectionActionTypes.ADD_SECTION: {
			const { mode, type, isNew } = action.payload as AddSectionPayload;
			const info = type !== 'category' ? { ...state.editSection } : { ...INITIAL_CONTENT };
			if (mode === 'add') {
				if (type === 'category') {
					if (isNew) {
						info.content = { ...INITIAL_CATEGORY };
					}
				}
			}

			return {
				...state,
				editSectionModal: true,
				editSection: info,
				editMode: mode,
				editActionType: type
			};
		}
		case SectionActionTypes.EDIT_SECTION: {
			const {
				data = INITIAL_CONTENT,
				mode = 'add',
				silent = false,
				selection = INITIAL_SELECTION
			} = action.payload as EditSectionPayload;
			const editSection = { ...INITIAL_CONTENT, ...clone(data) };
			editSection.content.subMenus
				.sort((a, b) => {
					if (Number(a.sequence) === Number(b.sequence)) return 0;
					if (Number(a.sequence) > Number(b.sequence)) return 1;
					return -1;
				})
				.forEach((m, i) => {
					const menu = m;
					menu.sequence = i + 1;
					let subCategories = get(menu, 'subCategories', []);
					subCategories = Array.isArray(subCategories) ? subCategories : [];
					subCategories
						.sort((a, b) => {
							if (Number(a.sequence) === Number(b.sequence)) return 0;
							if (Number(a.sequence) > Number(b.sequence)) return 1;
							return -1;
						})
						.forEach((c, ci) => {
							const category = c;
							category.sequence = ci + 1;
							let files = get(category, 'files', []);
							files = Array.isArray(files) ? files : [];
							files
								.sort((a, b) => {
									if (Number(a.fileSequence) === Number(b.fileSequence)) return 0;
									if (Number(a.fileSequence) > Number(b.fileSequence)) return 1;
									return -1;
								})
								.forEach((f, fi) => {
									const file = f;
									file.fileSequence = Number(file.fileSequence);
								});
						});
				});
			return {
				...state,
				editSectionModal: !silent,
				selected: selection || INITIAL_SELECTION,
				editSection: clone(editSection),
				editMode: mode
			};
		}
		case SectionActionTypes.EDIT_SECTION_INFO: {
			const { type, key, value, selected } = action.payload as EditSectionInfoPayload;
			const { editMode } = state;
			let { editSection } = state;
			editSection = clone(editSection);
			if (type === 'category') {
				set(editSection, `content.${key}`, value);
				if (!isEmpty(selected) && selected) {
					set(editSection, 'content.sequence', selected.sequence);
				}
			}
			if (type === 'submenu') {
				const menus: Array<any> = get(editSection.content, 'subMenus', []);
				let subMenu: any = {};
				if (selected) {
					subMenu = nth(menus, selected.sequence - 1);
					if (!subMenu) {
						subMenu = INITIAL_SUB_MENU;
						menus.push(subMenu);
					}
				}
				set(subMenu, `${key}`, value);
				if (!isEmpty(selected) && selected) {
					set(subMenu, 'sequence', selected.sequence);
				}
			}

			if (type === 'subcategory' && selected) {
				const menus: Array<any> = get(editSection.content, 'subMenus', []);
				const selectedMenu = find(menus, { id: selected.submenu });
				const subcategoies: Array<any> = get(selectedMenu, `subCategories`, []);
				let subCategory: any = {};
				if (selected) {
					subCategory = nth(subcategoies, selected.sequence - 1);
					if (!subCategory) {
						subCategory = INITIAL_SUB_CATEGORY;
						subcategoies.push(subCategory);
					}
				}
				set(subCategory, `${key}`, value);
				if (!isEmpty(selected) && selected) {
					set(subCategory, 'sequence', selected.sequence);
				}
			}
			if (type === 'file' && selected) {
				const menus: Array<any> = get(editSection.content, 'subMenus', []);
				const selectedMenu = find(menus, { id: selected.submenu });
				const subcategoies: Array<any> = get(selectedMenu, `subCategories`, []);
				const subCategory = find(subcategoies, { id: selected.subcategory });
				const Files: Array<any> = get(subCategory, 'files', []);
				let file: any = {};
				if (selected) {
					file = find(Files, { fileSequence: selected.sequence });
					if (!file) {
						file = INITIAL_FILE;
						Files.push(file);
					}
				}
				set(file, `${key}`, value);
				if (!isEmpty(selected) && selected) {
					set(file, 'fileSequence', selected.sequence);
				}
			}
			return {
				...state,
				editSectionModal: true,
				editSection
			};
		}
		default:
			return state;
	}
};

export default InvestorRelationsReducer;
