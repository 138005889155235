import { AjaxError } from 'rxjs/ajax';
import jwt from 'jwt-decode';
import get from 'lodash/get';
import { AuthActions, AuthState, LoginActionTypes } from './types';
import createReducer from '../../utils/createReducer';
import { ActionState } from '../../constants/api';

const INITIAL_STATE: AuthState = {
	isLoggedIn: false,
	user: {},
	login: ActionState.NONE,
	apiError: ActionState.NONE,
	logout: ActionState.NONE,
	errorData: {}
};
/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const AuthReducer = (state: AuthState = INITIAL_STATE, action: AuthActions): AuthState => {
	switch (action.type) {
		case LoginActionTypes.LOGIN: {
			return {
				...state,
				login: ActionState.LOADING
			};
		}
		case LoginActionTypes.CLEANLOGIN: {
			return {
				...state,
				...INITIAL_STATE
			};
		}
		case LoginActionTypes.LOGINSUCCESS: {
			const { response } = action.payload;
			const token = get(response, 'token', '');
			const decoded = jwt(token.replace('Bearer ', ''));
			const roles = get(decoded, 'roles', []);
			const formattedRoles = roles.map((d) => d.authority);
			return {
				...state,
				user: { ...response, ...decoded, userRoles: formattedRoles },
				isLoggedIn: true,
				login: ActionState.SUCCESS
			};
		}
		case LoginActionTypes.LOGINFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				login: ActionState.FAILURE,
				errorData: response
			};
		}
		case LoginActionTypes.APIERROR: {
			const response = action.payload as AjaxError;
			return {
				...state,
				apiError: ActionState.FAILURE,
				errorData: response
			};
		}
		case LoginActionTypes.LOGOUT: {
			return {
				...state,
				logout: ActionState.LOADING
			};
		}
		case LoginActionTypes.LOGOUTSUCCESS: {
			return {
				...state,
				...INITIAL_STATE,
				logout: ActionState.SUCCESS
			};
		}
		case LoginActionTypes.LOGOUTFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				logout: ActionState.FAILURE,
				errorData: response
			};
		}

		default:
			return state;
	}
};

const { REACT_APP_REDUCER_LOGIN = '' } = process.env;

export default createReducer(AuthReducer, {
	name: 'auth',
	password: REACT_APP_REDUCER_LOGIN,
	applicationName: 'DMartCorporateAdmin'
});
