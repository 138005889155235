import {
	LogOutAction,
	LogInSuccessAction,
	LogInFailureAction,
	LogOutSuccessAction,
	LogOutFailureAction,
	CleanLoginAction,
	LoginActionTypes,
	LoginPayload,
	LogInAction,
	ApiErrorAction
} from './types';

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const ClearLogin = (payload?: any, meta?: any): CleanLoginAction => {
	return {
		type: LoginActionTypes.CLEANLOGIN,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const APIError = (payload?: any, meta?: any): ApiErrorAction => {
	return {
		type: LoginActionTypes.APIERROR,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const LogIn = (payload: LoginPayload, meta?: any): LogInAction => {
	return {
		type: LoginActionTypes.LOGIN,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const LogInSuccess = (payload: any, meta?: any): LogInSuccessAction => {
	return {
		type: LoginActionTypes.LOGINSUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const LogInFailure = (payload: any, meta?: any): LogInFailureAction => {
	return {
		type: LoginActionTypes.LOGINFAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const LogOut = (payload?: any, meta?: any): LogOutAction => {
	return {
		type: LoginActionTypes.LOGOUT,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const LogOutSuccess = (payload?: any, meta?: any): LogOutSuccessAction => {
	return {
		type: LoginActionTypes.LOGOUTSUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const LogOutFailure = (payload?: any, meta?: any): LogOutFailureAction => {
	return {
		type: LoginActionTypes.LOGOUTFAILURE,
		payload,
		meta
	};
};
