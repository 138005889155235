import { nanoid } from '@reduxjs/toolkit';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';

interface actionType<T = any> {
	type: string;
	payload?: T;
	meta?: any;
}

const CreateAsyncAction = <T extends any = any>(actionName) => {
	const id = nanoid(6);
	const name = `${id}/${actionName}`;
	const success = `${id}/${actionName}_SUCCESS`;
	const cancel = `${id}/${actionName}_CANCEL`;
	const clear = `${id}/${actionName}_CLEAR`;
	const progress = `${id}/${actionName}_PROGRESS`;
	const failure = `${id}/${actionName}_FAILURE`;
	function action(payload: T, meta?: any): actionType<T>;
	function action(payload: T, meta: any): actionType<T>;
	function action(payload?: T, meta?: any): actionType<T>;
	function action(payload?: T, meta?: any) {
		return {
			type: name,
			payload,
			meta
		};
	}
	return {
		name,
		success,
		failure,
		progress,
		cancel,
		clear,
		action,
		successAction: (payload: AjaxResponse, meta?: any) => {
			return {
				type: success,
				payload,
				meta
			};
		},
		failureAction: (payload: AjaxError, meta?: any) => {
			return {
				type: failure,
				payload,
				meta
			};
		},
		cancelAction: (payload?: Partial<T>, meta?: any) => {
			return {
				type: cancel,
				payload,
				meta
			};
		},
		clearAction: (payload?: Partial<T>, meta?: any) => {
			return {
				type: clear,
				payload,
				meta
			};
		},
		progressAction: (payload: any, meta?: any) => {
			return {
				type: progress,
				payload,
				meta
			};
		}
	};
};

export default CreateAsyncAction;
