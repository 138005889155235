import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { of } from 'rxjs/internal/observable/of';
import { SectionActions, SectionActionTypes, SectionContent, SectionsContent } from './types';
import {
	GetInvestorRelationsSuccess,
	GetInvestorRelationsFailure,
	PublishSectionFailure,
	UnPublishSectionSuccess,
	UnPublishSectionFailure,
	DraftSectionSuccess,
	DraftSectionFailure,
	PublishSectionSuccess,
	ReorderSectionsSuccess,
	ReorderSectionsFailure
} from './actions';
import { RootState } from '../../reducers';
import { secureRequest } from '../../utils/functions';
import { APIError } from '../Login/actions';

const {
	REACT_APP_API_URL = '',
	REACT_APP_CONTENT_BASE_URL = '',
	REACT_APP_CONTENT_PRIVATE_BASE_URL = '',
	REACT_APP_CONTENT_API = ''
} = process.env;
const CONTENT_API = REACT_APP_API_URL + REACT_APP_CONTENT_BASE_URL + REACT_APP_CONTENT_API;
const PRIVATE_CONTENT_API =
	REACT_APP_API_URL + REACT_APP_CONTENT_PRIVATE_BASE_URL + REACT_APP_CONTENT_API;

/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
const GetInvestorRelations = (
	action$: ActionsObservable<SectionActions>,
	section$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(SectionActionTypes.GET_INVESTORS_RELATIONS),
		mergeMap((action) => {
			return secureRequest(
				{
					method: 'GET',
					url: `${PRIVATE_CONTENT_API}?contentPlaceholder=InvestorRelations_Details`,
					headers: {
						'Content-Type': 'application/json'
					},
					async: true,
					crossDomain: true
				},
				section$
			).pipe(
				map((response: AjaxResponse) => GetInvestorRelationsSuccess(response, action)),
				catchError((error: AjaxError) =>
					of(GetInvestorRelationsFailure(error, action), APIError(error, action))
				)
			);
		})
	);

export default GetInvestorRelations;

/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
export const PublishSectionEpic = (
	action$: ActionsObservable<SectionActions>,
	section$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(SectionActionTypes.PUBLISH_SECTION),
		mergeMap((action) => {
			const ActionPayload = action.payload as SectionContent;
			ActionPayload.contentTemplate = 'INVESTOR';
			return secureRequest(
				{
					// method: hasContentId(ActionPayload) ? 'PUT' : 'POST',
					method: 'PUT',
					url: CONTENT_API,
					body: JSON.stringify(
						Array.isArray(ActionPayload) ? ActionPayload : [ActionPayload]
					),
					headers: {
						'Content-Type': 'application/json'
					},
					timeout: 30000,
					async: true,
					crossDomain: true,
					withCredentials: true
				},
				section$
			).pipe(
				map((response: AjaxResponse) => PublishSectionSuccess(response, action)),
				catchError((error: AjaxError) =>
					of(PublishSectionFailure(error, action), APIError(error, action))
				)
			);
		})
	);

/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
export const UnpublishSectionEpic = (
	action$: ActionsObservable<SectionActions>,
	section$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(SectionActionTypes.UNPUBLISH_SECTION),
		mergeMap((action) => {
			const ActionPayload = action.payload as SectionContent;
			ActionPayload.contentTemplate = 'INVESTOR';
			return secureRequest(
				{
					// method: hasContentId(ActionPayload) ? 'PUT' : 'POST',
					method: 'PUT',
					url: CONTENT_API,
					body: JSON.stringify(
						Array.isArray(ActionPayload) ? ActionPayload : [ActionPayload]
					),
					headers: {
						'Content-Type': 'application/json'
					},
					timeout: 30000,
					async: true,
					crossDomain: true,
					withCredentials: true
				},
				section$
			).pipe(
				map((response: AjaxResponse) => UnPublishSectionSuccess(response, action)),
				catchError((error: AjaxError) =>
					of(UnPublishSectionFailure(error, action), APIError(error, action))
				)
			);
		})
	);
/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
export const DraftSectionEpic = (
	action$: ActionsObservable<SectionActions>,
	section$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(SectionActionTypes.DRAFT_SECTION),
		mergeMap((action) => {
			const ActionPayload = action.payload as SectionContent;
			ActionPayload.contentTemplate = 'INVESTOR';
			return secureRequest(
				{
					// method: hasContentId(ActionPayload) ? 'PUT' : 'POST',
					method: 'PUT',
					url: CONTENT_API,
					body: JSON.stringify(
						Array.isArray(ActionPayload) ? ActionPayload : [ActionPayload]
					),
					headers: {
						'Content-Type': 'application/json'
					},
					timeout: 30000,
					async: true,
					crossDomain: true,
					withCredentials: true
				},
				section$
			).pipe(
				map((response: AjaxResponse) => DraftSectionSuccess(response, action)),
				catchError((error: AjaxError) =>
					of(DraftSectionFailure(error, action), APIError(error, action))
				)
			);
		})
	);

/**
 *  Epic
 * @param {any} action$ -  Action to reorder the sections.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
export const ReorderSectionsEpic = (
	action$: ActionsObservable<SectionActions>,
	section$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(SectionActionTypes.REORDER_SECTIONS),
		mergeMap((action) => {
			const ActionPayload = action.payload as SectionsContent;
			ActionPayload.contentTemplate = 'INVESTOR';
			return secureRequest(
				{
					// method: ActionPayload.meta ? 'PUT' : 'POST',
					method: 'PUT',
					url: CONTENT_API,
					body: JSON.stringify(ActionPayload),
					headers: {
						'Content-Type': 'application/json'
					},
					timeout: 30000,
					async: true,
					crossDomain: true,
					withCredentials: true
				},
				section$
			).pipe(
				map((response: AjaxResponse) => ReorderSectionsSuccess(response, action)),
				catchError((error: AjaxError) =>
					of(ReorderSectionsFailure(error, action), APIError(error, action))
				)
			);
		})
	);
