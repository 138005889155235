import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { ActionState } from '../../constants/api';

export enum StoreAction {
	ADDSTORE = 'ADDSTORE',
	CLOSEEDITACTION = 'CLOSEEDITACTION',
	EDITSTORE = 'EDITSTORE',
	EDITSTOREINFO = 'EDITSTOREINFO',
	GETSTORELIST = 'GETSTORELIST',
	GETSTORELISTFAILURE = 'GETSTORELISTFAILURE',
	GETSTORELISTSUCCESS = 'GETSTORELISTSUCCESS',
	PUBLISHSTORE = 'PUBLISHSTORE',
	PUBLISHSTORESUCCESS = 'PUBLISHSTORESUCCESS',
	PUBLISHSTOREFAILURE = 'PUBLISHSTOREFAILURE',
	UNPUBLISHSTORE = 'UNPUBLISHSTORE',
	UNPUBLISHSTORESUCCESS = 'UNPUBLISHSTORESUCCESS',
	UNPUBLISHSTOREFAILURE = 'UNPUBLISHSTOREFAILURE',
	DRAFTSTORE = 'DRAFTSTORE',
	DRAFTSTORESUCCESS = 'DRAFTSTORESUCCESS',
	DRAFTSTOREFAILURE = 'DRAFTSTOREFAILURE'
}
export interface StoreFile {
	fileId: string;
	fileName: string;
	fileSequence: number;
}
export interface StoreInfo {
	title: string;
	subTitle: string;
	body: string;
	files: Array<StoreFile>;
	additionalData?: {
		[name: string]: any;
	};
}
export interface EditStoreInfoPayload {
	key: string;
	value: any;
}
export interface StoreContent {
	contentId?: string;
	placeholder: 'Home_NewStore';
	contentTemplate: 'RICH';
	page: 'Home';
	sequence: number;
	isPublished: boolean;
	content: StoreInfo;
}

export interface StoreState {
	getStoreList: ActionState;
	publishStore: ActionState;
	unpublishStore: ActionState;
	draftStore: ActionState;
	stores: Array<StoreContent>;
	editStore: StoreContent;
	editStoreModel: boolean;
	actiontype: 'add' | 'edit';
	errorData: Partial<AjaxError>;
}

export interface EditStoreInfoAction {
	type: typeof StoreAction.EDITSTOREINFO;
	payload: EditStoreInfoPayload;
	meta?: any;
}

export interface GetStoreListAction {
	type: typeof StoreAction.GETSTORELIST;
	payload?: string;
	meta?: any;
}

export interface GetStoreListSuccessAction {
	type: typeof StoreAction.GETSTORELISTSUCCESS;
	payload: AjaxResponse;
	meta?: any;
}

export interface GetStoreListFailureAction {
	type: typeof StoreAction.GETSTORELISTFAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface AddStoreAction {
	type: typeof StoreAction.ADDSTORE;
	payload?: any;
	meta?: any;
}

export interface EditStoreAction {
	type: typeof StoreAction.EDITSTORE;
	payload: StoreContent;
	meta?: any;
}

export interface PublishStoreAction {
	type: typeof StoreAction.PUBLISHSTORE;
	payload: StoreContent;
	meta: boolean;
}
export interface PublishStoreSuccessAction {
	type: typeof StoreAction.PUBLISHSTORESUCCESS;
	payload: AjaxResponse;
	meta?: any;
}
export interface PublishStoreFailureAction {
	type: typeof StoreAction.PUBLISHSTOREFAILURE;
	payload: AjaxError;
	meta?: any;
}
export interface UnpublishStoreAction {
	type: typeof StoreAction.UNPUBLISHSTORE;
	payload: StoreContent;
	meta: boolean;
}
export interface UnpublishStoreSuccessAction {
	type: typeof StoreAction.UNPUBLISHSTORESUCCESS;
	payload: AjaxResponse;
	meta?: any;
}
export interface UnpublishStoreFailureAction {
	type: typeof StoreAction.UNPUBLISHSTOREFAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface DrafthStoreAction {
	type: typeof StoreAction.DRAFTSTORE;
	payload: StoreContent;
	meta?: any;
}
export interface DraftStoreSuccessAction {
	type: typeof StoreAction.DRAFTSTORESUCCESS;
	payload: AjaxResponse;
	meta?: any;
}
export interface DraftStoreFailureAction {
	type: typeof StoreAction.DRAFTSTOREFAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface ClosteditAction {
	type: typeof StoreAction.CLOSEEDITACTION;
	payload?: any;
	meta?: any;
}

export interface StoreActions {
	type:
		| typeof StoreAction.ADDSTORE
		| typeof StoreAction.EDITSTORE
		| typeof StoreAction.EDITSTOREINFO
		| typeof StoreAction.GETSTORELIST
		| typeof StoreAction.GETSTORELISTSUCCESS
		| typeof StoreAction.GETSTORELISTFAILURE
		| typeof StoreAction.CLOSEEDITACTION
		| typeof StoreAction.PUBLISHSTORE
		| typeof StoreAction.PUBLISHSTORESUCCESS
		| typeof StoreAction.PUBLISHSTOREFAILURE
		| typeof StoreAction.UNPUBLISHSTORE
		| typeof StoreAction.UNPUBLISHSTORESUCCESS
		| typeof StoreAction.UNPUBLISHSTOREFAILURE
		| typeof StoreAction.DRAFTSTOREFAILURE
		| typeof StoreAction.DRAFTSTORE
		| typeof StoreAction.DRAFTSTORESUCCESS;
	payload?: EditStoreInfoPayload | StoreContent | AjaxResponse | AjaxError | any;
	meta?: any;
}
