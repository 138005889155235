import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { nanoid } from 'nanoid';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { FileState, FileActions, GetFiles, GET_FILE, GETFILE } from './types';
import { ActionState } from '../../constants/api';
import { LoginActionTypes } from '../Login/types';

const INITIAL_GET_FILE_STATE: GetFiles = {
	id: nanoid(6),
	getFile: ActionState.NONE,
	url: '',
	errorData: {}
};
const INITIAL_STATE: FileState = {
	files: {},
	uploadFile: ActionState.NONE,
	getFile: ActionState.NONE,
	getFiles: {}
};
/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const FileReducer = (state: FileState = INITIAL_STATE, action: FileActions): FileState => {
	switch (action.type) {
		case LoginActionTypes.CLEANLOGIN: {
			return {
				...state,
				...INITIAL_STATE
			};
		}

		case GET_FILE.name: {
			const { fileId } = action.payload as GETFILE;
			const stateFile = cloneDeep(get(state.getFiles, fileId, INITIAL_GET_FILE_STATE));
			stateFile.id = fileId;
			stateFile.getFile = ActionState.LOADING;
			return {
				...state,
				getFiles: {
					...state.getFiles,
					[fileId]: {
						...state.getFiles[fileId],
						...stateFile
					}
				},
				uploadFile: ActionState.LOADING
			};
		}
		case GET_FILE.clear: {
			const { fileId } = action.payload as GETFILE;
			const stateFile = cloneDeep(get(state.getFiles, fileId, INITIAL_GET_FILE_STATE));
			stateFile.getFile = ActionState.NONE;
			stateFile.url = '';
			stateFile.errorData = {};
			return {
				...state,
				getFiles: {
					...state.getFiles,
					[fileId]: {
						...state.getFiles[fileId],
						...stateFile
					}
				},
				uploadFile: ActionState.NONE
			};
		}
		case GET_FILE.success: {
			const { response, xhr } = action.payload as AjaxResponse;
			const { fileId, fileType } = action.meta.payload as GETFILE;
			const stateFile = cloneDeep(get(state.getFiles, fileId, INITIAL_GET_FILE_STATE));
			let contentType = xhr.getResponseHeader('Content-Type');
			if (contentType !== fileType && !isEmpty(fileType)) {
				contentType = fileType as string;
			}
			stateFile.getFile = ActionState.SUCCESS;
			const blob = new Blob([response], { type: contentType as string });
			const url = window.URL.createObjectURL(blob);
			stateFile.url = url;
			stateFile.blob = contentType;
			return {
				...state,
				getFiles: {
					...state.getFiles,
					[fileId]: {
						...state.getFiles[fileId],
						...stateFile
					}
				},
				uploadFile: ActionState.SUCCESS
			};
		}
		case GET_FILE.failure: {
			const response = action.payload as AjaxError;
			const { fileId } = action.meta.payload as GETFILE;
			const stateFile = cloneDeep(get(state.getFiles, fileId, INITIAL_GET_FILE_STATE));
			stateFile.getFile = ActionState.FAILURE;
			stateFile.errorData = response;
			return {
				...state,
				getFiles: {
					...state.getFiles,
					[fileId]: {
						...state.getFiles[fileId],
						...stateFile
					}
				},
				getFile: ActionState.FAILURE
			};
		}
		default:
			return state;
	}
};

export default FileReducer;
