import { REHYDRATE, persistReducer as originalPR, PersistConfig } from 'redux-persist';

import localforage from 'localforage';
import md5 from 'crypto-js/sha256';
import aes from 'crypto-js/rabbit';
import utf8 from 'crypto-js/enc-utf8';
import { Action, Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { isEmpty } from 'lodash';

/**
 *
 * @param {PersistConfig} config - Config
 * @param {Reducer} baseReducer - Reducer
 * @returns {Reducer} reducer
 */
export function persistReducer<S, A extends Action<any> = Action<any>>(
	config: PersistConfig<S, any, any, any>,
	baseReducer: Reducer<S, A>
): Reducer<S & PersistPartial, A> {
	const reducer = originalPR(config, baseReducer);

	return (state, action: any) => {
		const newState: any = reducer(state, action);

		// all that to add the _rev to the _persist state
		if (
			action.type === REHYDRATE &&
			newState._persist &&
			action.payload &&
			action.payload._persist
		) {
			newState._persist._rev = action.payload._persist._rev;
		}

		return newState;
	};
}

/**
 *
 */
export class LocalForageDBStorage {
	db: LocalForage;

	hash = '';

	password = '';

	/**
	 *
	 * @param {any} db - Pouch DB
	 * @param {any} password - Password
	 * @param {any} options - Options
	 * @returns {void}
	 */
	constructor(db, password = '', options = {}) {
		if (typeof db !== 'string' &&  isEmpty(options)) {
			this.db = db;
		} else {
			this.hash = db + password;
			this.db = localforage.createInstance({
				name: md5(db).toString()
			});
			this.password = password;
		}
	}

	encrypt = (value, stringify = false) => {
		if (stringify) {
			return aes.encrypt(JSON.stringify(value), this.hash).toString();
		}
		return aes.encrypt(value, this.hash).toString();
	};

	decrypt = (value, parse = false) => {
		if (parse) {
			const d = aes.decrypt(value, this.hash);
			return JSON.parse(d.toString(utf8));
		}
		return aes.decrypt(value, this.hash);
	};

	/**
	 * @param {any}  key - Key
	 * @returns {any} key
	 */
	async getItem(key: string): Promise<any> {
		try {
			const enc = md5(key).toString();
			let doc = await this.db.getItem<any>(enc);
			doc = this.decrypt(doc, true);
			if (doc._persist) {
				doc._persist._rev = doc._rev;
				doc._persist = JSON.stringify(doc._persist);
			}
			return JSON.stringify(doc);
		} catch (error) {
			console.log('getItem', error);
			return '{}';
		}
	}

	/**
	 * @param {any}  key - Key
	 * @param {any}  value - Key
	 * @returns {any} key
	 */
	async setItem(key, value) {
		try {
			const enc = md5(key).toString();
			const doc = JSON.parse(value);
			doc._persist = JSON.parse(doc._persist);
			const setItem = await this.db.setItem(enc, this.encrypt(doc, true));
			return setItem;
		} catch (error) {
			console.log('setItem', error);
			return true;
		}
	}

	/**
	 * @param {any}  key - Key
	 * @param {any}  value - Key
	 * @returns {any} key
	 */
	async removeItem(key, value) {
		try {
			const enc = md5(key).toString();
			const remove = await this.db.removeItem(enc);
			return remove;
		} catch (error) {
			console.log('removeItem', error);
			return true;
		}
	}
}
