import { AjaxError } from 'rxjs/ajax';
import { ActionState } from '../../constants/api';

export enum LoginActionTypes {
	LOGIN = 'LOGIN',
	LOGINSUCCESS = 'LOGINSUCCESS',
	LOGINFAILURE = 'LOGINFAILURE',

	LOGOUT = 'LOGOUT',
	LOGOUTSUCCESS = 'LOGOUTSUCCESS',
	LOGOUTFAILURE = 'LOGOUTFAILURE',

	CLEANLOGIN = 'CLEANLOGIN',
	APIERROR = 'APIERROR'
}

export interface LoginPayload {
	userName: string;
	password: string;
}

export interface Token {
	token: string;
	userName: string;
}
export interface AuthState {
	isLoggedIn: boolean;
	user: Partial<Token>;
	login: ActionState;
	logout: ActionState;
	errorData: Partial<AjaxError>;
	apiError: ActionState;
}

export interface LogInAction {
	type: typeof LoginActionTypes.LOGIN;
	payload: LoginPayload;
	meta?: any;
}
export interface ApiErrorAction {
	type: typeof LoginActionTypes.APIERROR;
	payload: any;
	meta?: any;
}

export interface CleanLoginAction {
	type: typeof LoginActionTypes.CLEANLOGIN;
	payload: any;
	meta?: any;
}
export interface LogInSuccessAction {
	type: typeof LoginActionTypes.LOGINSUCCESS;
	payload: any;
	meta?: any;
}

export interface LogInFailureAction {
	type: typeof LoginActionTypes.LOGINFAILURE;
	payload: any;
	meta?: any;
}
export interface LogOutAction {
	type: typeof LoginActionTypes.LOGOUT;
	payload?: any;
	meta?: any;
}
export interface LogOutSuccessAction {
	type: typeof LoginActionTypes.LOGOUTSUCCESS;
	payload?: any;
	meta?: any;
}
export interface LogOutFailureAction {
	type: typeof LoginActionTypes.LOGOUTFAILURE;
	payload?: any;
	meta?: any;
}

export interface AuthActions {
	type: LoginActionTypes;
	payload: LoginPayload | any;
	meta?: any;
}
