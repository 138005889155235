import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import {
	UploadPayload,
	UploadAction,
	FileActionTypes,
	UploadSuccessAction,
	UploadFailureAction,
	UploadProgressAction,
	UploadProgressErrorAction,
	ClearFileUploadAction
} from './types';

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UploadFile = (payload: UploadPayload, meta?: any): UploadAction => {
	return {
		type: FileActionTypes.UPLOADFILE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UploadFileSuccess = (payload: AjaxResponse, meta?: any): UploadSuccessAction => {
	return {
		type: FileActionTypes.UPLOADFILESUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UploadFileFailure = (payload: AjaxError, meta?: any): UploadFailureAction => {
	return {
		type: FileActionTypes.UPLOADFILEFAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UploadFileProgress = (payload: any, meta?: any): UploadProgressAction => {
	return {
		type: FileActionTypes.UPLOADFILEPROGRESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UploadFileProgressFailure = (payload?: any, meta?: any): UploadProgressErrorAction => {
	return {
		type: FileActionTypes.UPLOADFILEPROGRESSERROR,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const ClearFileUpload = (payload?: any, meta?: any): ClearFileUploadAction => {
	return {
		type: FileActionTypes.CLEARFILEUPLOAD,
		payload,
		meta
	};
};
