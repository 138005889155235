import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import {
	SectionActionTypes,
	GetInvestorRelationsDataAction,
	GetInvestorRelationsSuccessAction,
	GetInvestorRelationsFailureAction,
	EditSectionInfoPayload,
	AddSectionPayload,
	EditSectionInfoAction,
	EditSectionPayload,
	EditSectionAction,
	AddSectionAction,
	CloseEditSectionAction,
	DraftSectionAction,
	SectionContent,
	DraftSectionSuccessAction,
	DraftSectionFailureAction,
	PublishSectionAction,
	PublishSectionSuccessAction,
	PublishSectionFailureAction,
	UnpublishSectionAction,
	UnpublishSectionSuccessAction,
	UnpublishSectionFailureAction,
	SectionsContent,
	ReorderSectionsAction,
	ReorderSectionsSuccessAction,
	ReorderSectionsFailureAction
} from './types';

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetInvestorRelationsData = (
	payload?: any,
	meta?: any
): GetInvestorRelationsDataAction => {
	return {
		type: SectionActionTypes.GET_INVESTORS_RELATIONS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetInvestorRelationsSuccess = (
	payload: AjaxResponse,
	meta?: any
): GetInvestorRelationsSuccessAction => {
	return {
		type: SectionActionTypes.GET_INVESTORS_RELATIONS_SUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetInvestorRelationsFailure = (
	payload: AjaxError,
	meta?: any
): GetInvestorRelationsFailureAction => {
	return {
		type: SectionActionTypes.GET_INVESTORS_RELATIONS_FAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const EditSectionInfo = (
	payload: EditSectionInfoPayload,
	meta?: any
): EditSectionInfoAction => {
	return {
		type: SectionActionTypes.EDIT_SECTION_INFO,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const EditSection = (payload: EditSectionPayload, meta?: any): EditSectionAction => {
	return {
		type: SectionActionTypes.EDIT_SECTION,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const CloseEditSection = (payload?: any, meta?: any): CloseEditSectionAction => {
	return {
		type: SectionActionTypes.CLOSE_EDIT_SECTION,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const AddSection = (payload: AddSectionPayload, meta?: any): AddSectionAction => {
	return {
		type: SectionActionTypes.ADD_SECTION,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const DraftSection = (payload: SectionContent, meta = false): DraftSectionAction => {
	return {
		type: SectionActionTypes.DRAFT_SECTION,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const DraftSectionSuccess = (payload: any, meta?: any): DraftSectionSuccessAction => {
	return {
		type: SectionActionTypes.DRAFT_SECTION_SUCCESS,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const DraftSectionFailure = (payload: any, meta?: any): DraftSectionFailureAction => {
	return {
		type: SectionActionTypes.DRAFT_SECTION_FAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const PublishSection = (payload: SectionContent, meta: any): PublishSectionAction => {
	return {
		type: SectionActionTypes.PUBLISH_SECTION,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const PublishSectionSuccess = (payload: any, meta?: any): PublishSectionSuccessAction => {
	return {
		type: SectionActionTypes.PUBLISH_SECTION_SUCCESS,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const PublishSectionFailure = (payload: any, meta?: any): PublishSectionFailureAction => {
	return {
		type: SectionActionTypes.PUBLISH_SECTION_FAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UnPublishSection = (payload: SectionContent, meta = false): UnpublishSectionAction => {
	return {
		type: SectionActionTypes.UNPUBLISH_SECTION,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UnPublishSectionSuccess = (
	payload: any,
	meta?: any
): UnpublishSectionSuccessAction => {
	return {
		type: SectionActionTypes.UNPUBLISH_SECTION_SUCCESS,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UnPublishSectionFailure = (
	payload: any,
	meta?: any
): UnpublishSectionFailureAction => {
	return {
		type: SectionActionTypes.UNPUBLISH_SECTION_FAILURE,
		payload,
		meta
	};
};

/**
 * Action to reorder the sections
 * @param {any} payload -  Payload Array of sections.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const ReorderSections = (payload: SectionsContent, meta = false): ReorderSectionsAction => {
	return {
		type: SectionActionTypes.REORDER_SECTIONS,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const ReorderSectionsSuccess = (payload: any, meta?: any): ReorderSectionsSuccessAction => {
	return {
		type: SectionActionTypes.REORDER_SECTIONS_SUCCESS,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const ReorderSectionsFailure = (payload: any, meta?: any): ReorderSectionsFailureAction => {
	return {
		type: SectionActionTypes.REORDER_SECTIONS_FAILURE,
		payload,
		meta
	};
};
