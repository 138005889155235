import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import {
	StoreAction,
	EditStoreAction,
	EditStoreInfoAction,
	AddStoreAction,
	ClosteditAction,
	GetStoreListAction,
	GetStoreListFailureAction,
	GetStoreListSuccessAction,
	PublishStoreAction,
	UnpublishStoreAction,
	EditStoreInfoPayload,
	StoreContent,
	DrafthStoreAction,
	DraftStoreSuccessAction,
	DraftStoreFailureAction,
	UnpublishStoreSuccessAction,
	UnpublishStoreFailureAction,
	PublishStoreFailureAction,
	PublishStoreSuccessAction
} from './types';

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const EditStoreInfo = (payload: EditStoreInfoPayload, meta?: any): EditStoreInfoAction => {
	return {
		type: StoreAction.EDITSTOREINFO,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetStoreList = (payload?: any, meta?: any): GetStoreListAction => {
	return {
		type: StoreAction.GETSTORELIST,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetStoreListSuccess = (
	payload: AjaxResponse,
	meta?: any
): GetStoreListSuccessAction => {
	return {
		type: StoreAction.GETSTORELISTSUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetStoreListFailure = (payload: AjaxError, meta?: any): GetStoreListFailureAction => {
	return {
		type: StoreAction.GETSTORELISTFAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const EditStore = (payload: StoreContent, meta?: any): EditStoreAction => {
	return {
		type: StoreAction.EDITSTORE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const AddStore = (payload: any, meta?: any): AddStoreAction => {
	return {
		type: StoreAction.ADDSTORE,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const PublishStore = (payload: StoreContent, meta = false): PublishStoreAction => {
	return {
		type: StoreAction.PUBLISHSTORE,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const PublishStoreSuccess = (
	payload: AjaxResponse,
	meta?: any
): PublishStoreSuccessAction => {
	return {
		type: StoreAction.PUBLISHSTORESUCCESS,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const PublishStoreFailure = (payload: AjaxError, meta?: any): PublishStoreFailureAction => {
	return {
		type: StoreAction.PUBLISHSTOREFAILURE,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UnPublishStore = (payload: StoreContent, meta = false): UnpublishStoreAction => {
	return {
		type: StoreAction.UNPUBLISHSTORE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UnPublishStoreSuccess = (
	payload: AjaxResponse,
	meta?: any
): UnpublishStoreSuccessAction => {
	return {
		type: StoreAction.UNPUBLISHSTORESUCCESS,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UnPublishStoreFailure = (
	payload: AjaxError,
	meta?: any
): UnpublishStoreFailureAction => {
	return {
		type: StoreAction.UNPUBLISHSTOREFAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const DraftStore = (payload: StoreContent, meta?: any): DrafthStoreAction => {
	return {
		type: StoreAction.DRAFTSTORE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const DraftStorSuccess = (payload: AjaxResponse, meta?: any): DraftStoreSuccessAction => {
	return {
		type: StoreAction.DRAFTSTORESUCCESS,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const DraftStoreFailure = (payload: AjaxError, meta?: any): DraftStoreFailureAction => {
	return {
		type: StoreAction.DRAFTSTOREFAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const CloseEdit = (payload: any, meta?: any): ClosteditAction => {
	return {
		type: StoreAction.CLOSEEDITACTION,
		payload,
		meta
	};
};
