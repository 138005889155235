import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import set from 'lodash/set';
import clone from 'lodash/cloneDeep';
import {
	RichContentActionTypes,
	RichContentState,
	RichContentActions,
	MetaActions,
	RichContent,
	Content,
	EditContentInfoPayload
} from './types';
import { ActionState } from '../../constants/api';

const INITIAL_TEMPLATE: RichContent = {
	body: '',
	additionalData: {},
	files: [],
	subTitle: '',
	title: ''
};
const INITIAL_CONTENT: Content<RichContent> = {
	contentTemplate: 'RICH',
	isPublished: false,
	page: 'SocialOutReach',
	placeholder: 'Social_Outreach',
	content: INITIAL_TEMPLATE,
	sequence: 0
};
const INITIAL_STATE: RichContentState = {
	contents: [],
	draftContent: ActionState.NONE,
	unpublishContent: ActionState.NONE,
	getContentList: ActionState.NONE,
	publishContent: ActionState.NONE,
	reorderContent: ActionState.NONE,
	editContents: {},
	editContentsModal: false,
	editContentsMode: 'add',
	errorData: {}
};
/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const RitchContentReducer = (
	state: RichContentState = INITIAL_STATE,
	action: RichContentActions
): RichContentState => {
	switch (action.type) {
		case RichContentActionTypes.GETRICHCONTENTLIST: {
			return {
				...state,
				getContentList: ActionState.LOADING
			};
		}
		case RichContentActionTypes.GETRICHCONTENTLISTSUCCESS: {
			const { response } = action.payload as AjaxResponse;
			return {
				...state,
				getContentList: ActionState.SUCCESS,
				contents: response
			};
		}
		case RichContentActionTypes.GETRICHCONTENTLISTFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				getContentList: ActionState.FAILURE,
				errorData: response
			};
		}
		case RichContentActionTypes.ADDRICHCONTENT: {
			return {
				...state,
				editContents: {
					...INITIAL_CONTENT,
					sequence: Array.isArray(state.contents) ? state.contents.length + 1 : 1
				},
				editContentsMode: 'add',
				editContentsModal: true
			};
		}
		case RichContentActionTypes.CLOSERITCHCONTENTMODAL: {
			return {
				...state,
				editContentsModal: false
			};
		}
		case RichContentActionTypes.EDITRICHCONTENT: {
			const response = action.payload as Content<RichContent>;
			return {
				...state,
				editContents: response,
				editContentsModal: true,
				editContentsMode: 'edit'
			};
		}
		case RichContentActionTypes.EDITRITCHCONTENTINFO: {
			const { key, value } = action.payload as EditContentInfoPayload;
			const { editContents } = state;
			const data = clone(editContents);
			set(data, `${key}`, value);
			return {
				...state,
				editContents: { ...data }
			};
		}
		case RichContentActionTypes.RICHCONTENTACTION: {
			const { type } = action.meta as MetaActions;
			return {
				...state,
				publishContent: type === 'publish' ? ActionState.LOADING : state.publishContent,
				draftContent: type === 'draft' ? ActionState.LOADING : state.draftContent,
				unpublishContent:
					type === 'unpublish' ? ActionState.LOADING : state.unpublishContent,
				reorderContent: type === 'reorder' ? ActionState.LOADING : state.reorderContent
			};
		}
		case RichContentActionTypes.RICHCONTENTACTIONSUCCESS: {
			const { type } = action.meta.meta as MetaActions;
			return {
				...state,
				publishContent: type === 'publish' ? ActionState.SUCCESS : state.publishContent,
				draftContent: type === 'draft' ? ActionState.SUCCESS : state.draftContent,
				unpublishContent:
					type === 'unpublish' ? ActionState.SUCCESS : state.unpublishContent,
				reorderContent: type === 'reorder' ? ActionState.SUCCESS : state.reorderContent
			};
		}
		case RichContentActionTypes.RICHCONTENTACTIONFAILURE: {
			const { type } = action.meta.meta as MetaActions;
			const response = action.payload as AjaxError;
			return {
				...state,
				publishContent: type === 'publish' ? ActionState.FAILURE : state.publishContent,
				draftContent: type === 'draft' ? ActionState.FAILURE : state.draftContent,
				unpublishContent:
					type === 'unpublish' ? ActionState.FAILURE : state.unpublishContent,
				reorderContent: type === 'reorder' ? ActionState.FAILURE : state.reorderContent,
				errorData: response
			};
		}
		default:
			return state;
	}
};

export default RitchContentReducer;
