import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { ActionState } from '../../constants/api';

export enum FileActionTypes {
	UPLOADFILEPROGRESS = 'UPLOADPROGRESS',
	UPLOADFILEPROGRESSERROR = 'UPLOADPROGRESSERROR',
	UPLOADFILE = 'UPLOADFILE',
	CLEARFILEUPLOAD = 'CLEARFILEUPLOAD',
	UPLOADFILESUCCESS = 'UPLOADFILESUCCESS',
	UPLOADFILEFAILURE = 'UPLOADFILEFAILURE'
}
export interface UploadPayload {
	file: File;
}
export interface UploadPercentPayload {
	percent: number;
}
export interface FileState {
	uploadFile: ActionState;
	details: string;
	percent: number;
	errorData: Partial<AjaxError>;
}

export interface UploadAction {
	type: typeof FileActionTypes.UPLOADFILE;
	payload: UploadPayload;
	meta?: any;
}

export interface UploadSuccessAction {
	type: typeof FileActionTypes.UPLOADFILESUCCESS;
	payload: AjaxResponse;
	meta?: any;
}

export interface UploadFailureAction {
	type: typeof FileActionTypes.UPLOADFILEFAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface UploadProgressAction {
	type: typeof FileActionTypes.UPLOADFILEPROGRESS;
	payload: ProgressEvent;
	meta?: any;
}

export interface UploadProgressErrorAction {
	type: typeof FileActionTypes.UPLOADFILEPROGRESSERROR;
	payload: any;
	meta?: any;
}

export interface ClearFileUploadAction {
	type: typeof FileActionTypes.CLEARFILEUPLOAD;
	payload: any;
	meta?: any;
}
export interface FileActions {
	type: FileActionTypes;
	payload?: UploadPayload | AjaxResponse | AjaxError | any;
	meta?: any;
}
