import { Action, Reducer } from 'redux';
import md5 from 'crypto-js/md5';
import sha256 from 'crypto-js/sha256';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { persistReducer, LocalForageDBStorage } from './persist-localforage';

interface CreateReducerConfig {
	/**
	 * name of reducer
	 */
	name: string;
	/**
	 * password to encrypt reducer
	 */
	password: string;
	/**
	 * Application name
	 */
	applicationName: string;
}
/**
 *
 * @param {Reducer} reducer - Reducer
 * @param {CreateReducerConfig} config - Password
 * @returns {Reducer} NewReducer
 */
const createReducer = <S, A extends Action<any> = Action<any>>(
	reducer: Reducer<S, A>,
	config: CreateReducerConfig
): Reducer<S & PersistPartial, A> => {
	const { applicationName, name } = config;
	const password = sha256(name + applicationName + config.password).toString();
	const reducerName = `${applicationName}-${name}`;
	const hash = sha256(`app_${md5(reducerName)}${password}`).toString();
	const storage = new LocalForageDBStorage(hash, password);
	const persistedReducer = persistReducer({ storage, key: hash }, reducer);
	return persistedReducer;
};
export default createReducer;
