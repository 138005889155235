import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';
import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { of } from 'rxjs/internal/observable/of';
import { PlainContentActions, PlainContentActionTypes, PlainContent } from './types';
import {
	GetPlainContentListFailure,
	GetPlainContentListSuccess,
	PublishPlainContentFailure,
	PublishPlainContentSuccess,
	UnPublishPlainContentFailure,
	UnPublishPlainContentSuccess,
	DraftPlainContentFailure,
	DraftPlainContentSuccess
} from './actions';
import { RootState } from '../../reducers';
import { secureRequest } from '../../utils/functions';
import { APIError } from '../Login/actions';

const {
	REACT_APP_API_URL = '',
	REACT_APP_CONTENT_BASE_URL = '',
	REACT_APP_CONTENT_PRIVATE_BASE_URL = '',
	REACT_APP_CONTENT_API = ''
} = process.env;
const CONTENT_API = REACT_APP_API_URL + REACT_APP_CONTENT_BASE_URL + REACT_APP_CONTENT_API;
const PRIVATE_CONTENT_API =
	REACT_APP_API_URL + REACT_APP_CONTENT_PRIVATE_BASE_URL + REACT_APP_CONTENT_API;
/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
export const GetPlainContent = (
	action$: ActionsObservable<PlainContentActions>,
	plainContent$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(PlainContentActionTypes.GETPLAINCONTENTLIST),
		switchMap((action) => {
			return secureRequest(
				{
					method: 'GET',
					url: `${PRIVATE_CONTENT_API}?contentPlaceholder=AboutUs_StoreCount`,
					headers: {
						'Content-Type': 'application/json'
					},
					async: true,
					crossDomain: true
				},
				plainContent$
			).pipe(
				map((response: AjaxResponse) => GetPlainContentListSuccess(response, action)),
				catchError((error: AjaxError) =>
					of(GetPlainContentListFailure(error, action), APIError(error, action))
				)
			);
		})
	);

/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
export const PublishPlainContent = (
	action$: ActionsObservable<PlainContentActions>,
	plainContent$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(PlainContentActionTypes.PUBLISHPLAINCONTENT),
		switchMap((action) => {
			const ActionPayload = action.payload as PlainContent;
			ActionPayload.isPublished = true;
			ActionPayload.contentTemplate = 'PLAIN';
			return secureRequest(
				{
					method: 'PUT',
					url: CONTENT_API,
					body: JSON.stringify([ActionPayload]),
					headers: {
						'Content-Type': 'application/json'
					},
					timeout: 30000,
					async: true,
					crossDomain: true
				},
				plainContent$
			).pipe(
				map((response: AjaxResponse) => PublishPlainContentSuccess(response, action)),
				catchError((error: AjaxError) =>
					of(PublishPlainContentFailure(error, action), APIError(error, action))
				)
			);
		})
	);

/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
export const UnpublishPlainContent = (
	action$: ActionsObservable<PlainContentActions>,
	plainContent$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(PlainContentActionTypes.UNPUBLISHPLAINCONTENT),
		switchMap((action) => {
			const ActionPayload = action.payload as PlainContent;
			ActionPayload.isPublished = false;
			ActionPayload.contentTemplate = 'PLAIN';
			return secureRequest(
				{
					method: 'PUT',
					url: CONTENT_API,
					body: JSON.stringify([ActionPayload]),
					headers: {
						'Content-Type': 'application/json'
					},
					timeout: 30000,
					async: true,
					crossDomain: true
				},
				plainContent$
			).pipe(
				map((response: AjaxResponse) => UnPublishPlainContentSuccess(response, action)),
				catchError((error: AjaxError) =>
					of(UnPublishPlainContentFailure(error, action), APIError(error, action))
				)
			);
		})
	);
/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
export const DraftPlainContent = (
	action$: ActionsObservable<PlainContentActions>,
	plainContent$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(PlainContentActionTypes.DRAFTPLAINCONTENT),
		switchMap((action) => {
			const ActionPayload = action.payload as PlainContent;
			ActionPayload.isPublished = false;
			ActionPayload.contentTemplate = 'PLAIN';
			return secureRequest(
				{
					method: 'PUT',
					url: CONTENT_API,
					body: JSON.stringify([ActionPayload]),
					headers: {
						'Content-Type': 'application/json'
					},
					timeout: 30000,
					async: true,
					crossDomain: true
				},
				plainContent$
			).pipe(
				map((response: AjaxResponse) => DraftPlainContentSuccess(response, action)),
				catchError((error: AjaxError) =>
					of(DraftPlainContentFailure(error, action), APIError(error, action))
				)
			);
		})
	);
