import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { ActionState } from '../../constants/api';

export enum RichContentActionTypes {
	GETRICHCONTENTLIST = 'GETRICHCONTENTLIST',
	GETRICHCONTENTLISTFAILURE = 'GETRICHCONTENTLISTFAILURE',
	GETRICHCONTENTLISTSUCCESS = 'GETRICHCONTENTLISTSUCCESS',
	RICHCONTENTACTION = 'RICHCONTENTACTION',
	RICHCONTENTACTIONSUCCESS = 'RICHCONTENTACTIONSUCCESS',
	RICHCONTENTACTIONFAILURE = 'RICHCONTENTACTIONFAILURE',
	ADDRICHCONTENT = 'ADDRICHCONTENT',
	EDITRICHCONTENT = 'EDITRICHCONTENT',
	EDITRITCHCONTENTINFO = 'EDITRITCHCONTENTINFO',
	CLOSERITCHCONTENTMODAL = 'CLOSERITCHCONTENTMODAL'
}
interface AdditionalData {
	additionalData?: Record<string, any>;
}
export interface FileInfo extends AdditionalData {
	fileId: string;
	fileName: string;
	fileSequence: number;
	isPublished: boolean;
}
export interface RichContent extends AdditionalData {
	title: string;
	subTitle: string;
	body: string;
	files: Array<FileInfo>;
}
type PlaceHolder =
	| 'Home_NewStore'
	| 'AboutUs_StoreCount'
	| 'InvestorRelations_Details'
	| 'Social_Outreach';
type Page = 'AboutUs' | 'Home' | 'InvestorRelationPage' | 'SocialOutReach';
type Template = 'PLAIN' | 'INVESTOR' | 'RICH';
type Actions = 'publish' | 'unpublish' | 'draft' | 'reorder';
export interface MetaActions {
	type: Actions;
}
export interface EditContentInfoPayload {
	key: string;
	value: any;
}
export interface GetRichContentPayload {
	contentPlaceholder: PlaceHolder;
	page: Page;
	isPublished?: boolean;
}
export interface SubCategoryInfo extends AdditionalData {
	name: string;
	sequence: number;
	isPublished: boolean;
	display?: boolean;
	files: Array<FileInfo>;
}
export interface SubMenuInfo extends AdditionalData {
	name: string;
	pageTitle: string;
	sequence: number;
	isPublished: boolean;
	subCategories: Array<SubCategoryInfo>;
}
export interface InvestorContent extends AdditionalData {
	investorCategoryName: string;
	sequence: number;
	isPublished: boolean;
	subMenus: Array<SubMenuInfo>;
}
export interface PlainContent {
	body: any;
}
export interface Content<T extends Record<string, any> = any> {
	contentId?: string;
	placeholder: PlaceHolder;
	contentTemplate: Template;
	page: Page;
	sequence: number;
	isPublished: boolean;
	content: T;
}
export interface RichContentState {
	getContentList: ActionState;
	publishContent: ActionState;
	unpublishContent: ActionState;
	draftContent: ActionState;
	reorderContent: ActionState;
	contents: Array<Content<RichContent>>;
	editContents: Partial<Content<RichContent>>;
	editContentsModal: boolean;
	editContentsMode: 'add' | 'edit';
	errorData: Partial<AjaxError>;
}

export interface GetRichContentListAction {
	type: typeof RichContentActionTypes.GETRICHCONTENTLIST;
	payload: GetRichContentPayload;
	meta?: any;
}

export interface GetRichContentListSuccessAction {
	type: typeof RichContentActionTypes.GETRICHCONTENTLISTSUCCESS;
	payload: AjaxResponse;
	meta?: any;
}

export interface GetRichContentListFailureAction {
	type: typeof RichContentActionTypes.GETRICHCONTENTLISTFAILURE;
	payload: AjaxError;
	meta?: any;
}

export type ContentAction = {
	(payload: Content<RichContent>, meta: MetaActions): RichContentAction;
	(payload: Array<Content<RichContent>>, meta: MetaActions): RichContentAction;
};
export interface RichContentAction {
	type: typeof RichContentActionTypes.RICHCONTENTACTION;
	payload: Content<RichContent> | Array<Content<RichContent>>;
	meta: MetaActions;
}

export interface RichContentActionSuccess {
	type: typeof RichContentActionTypes.RICHCONTENTACTIONSUCCESS;
	payload: AjaxResponse;
	meta?: any;
}

export interface RichContentActionFailure {
	type: typeof RichContentActionTypes.RICHCONTENTACTIONFAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface AddContentAction {
	type: typeof RichContentActionTypes.ADDRICHCONTENT;
	payload?: any;
	meta?: any;
}

export interface EditContentAction {
	type: typeof RichContentActionTypes.EDITRICHCONTENT;
	payload: Content<RichContent>;
	meta?: any;
}

export interface EditContentInfoAction {
	type: typeof RichContentActionTypes.EDITRITCHCONTENTINFO;
	payload: EditContentInfoPayload;
	meta?: any;
}

export interface CloseContentModalAction {
	type: typeof RichContentActionTypes.CLOSERITCHCONTENTMODAL;
	payload?: any;
	meta?: any;
}
export interface RichContentActions {
	type: RichContentActionTypes;
	payload?:
		| GetRichContentPayload
		| EditContentInfoPayload
		| Content<RichContent>
		| Array<Content<RichContent>>
		| AjaxResponse
		| AjaxError
		| any;
	meta?: any;
}
