import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ajax, AjaxResponse, AjaxError } from 'rxjs/ajax';
import { of } from 'rxjs';
import { AuthActions, LoginPayload, LoginActionTypes } from './types';
import { LogInSuccess, LogInFailure, LogOutFailure, LogOutSuccess } from './actions';
import { RootState } from '../../reducers';

const {
	REACT_APP_API_URL = '',
	REACT_APP_CONTENT_BASE_URL = '',
	REACT_APP_LOGIN_API = '',
	REACT_APP_LOGOUT_API = ''
} = process.env;
const LogInAPI = REACT_APP_API_URL + REACT_APP_CONTENT_BASE_URL + REACT_APP_LOGIN_API;
const LogOutAPI = REACT_APP_API_URL + REACT_APP_CONTENT_BASE_URL + REACT_APP_LOGOUT_API;
/**
 *  Epic
 * @param {any} action$ - Action.
 * @param {any} state$ - State.
 * @returns {ActionsObservable} Epic.
 */
export const LoginEpic = (action$: ActionsObservable<AuthActions>) =>
	action$.pipe(
		ofType(LoginActionTypes.LOGIN),
		switchMap((action) => {
			const ActionPayload = action.payload as LoginPayload;
			return ajax({
				method: 'POST',
				url: LogInAPI,
				body: JSON.stringify(ActionPayload),
				headers: {
					'Content-Type': 'application/json'
				},
				async: true,
				crossDomain: true
			}).pipe(
				map((response: AjaxResponse) => LogInSuccess(response, action)),
				catchError((error: AjaxError) => of(LogInFailure(error, action)))
			);
		})
	);
/**
 *  Epic
 * @param {any} action$ - Action.
 * @param {any} state$ - State.
 * @returns {ActionsObservable} Epic.
 */
export const LogoutEpic = (
	action$: ActionsObservable<AuthActions>,
	state$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(LoginActionTypes.LOGOUT),
		switchMap((action) => {
			const ActionPayload = {
				token: state$.value.auth.user.token
			};
			return ajax({
				method: 'POST',
				url: LogOutAPI,
				body: JSON.stringify(ActionPayload),
				headers: {
					'Content-Type': 'application/json'
				},
				async: true,
				crossDomain: true,
				withCredentials: true
			}).pipe(
				map((response: AjaxResponse) => LogOutSuccess(response, action)),
				catchError((error: AjaxError) => of(LogOutFailure(error, action)))
			);
		})
	);
