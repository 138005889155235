import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { ActionState } from '../../constants/api';

export enum SectionActionTypes {
	GET_INVESTORS_RELATIONS = 'GET_INVESTORS_RELATIONS',
	GET_INVESTORS_RELATIONS_FAILURE = 'GET_INVESTORS_RELATIONS_FAILURE',
	GET_INVESTORS_RELATIONS_SUCCESS = 'GET_INVESTORS_RELATIONS_SUCCESS',
	ADD_SECTION = 'ADD_SECTION',
	ADD_GROUP = 'ADD_GROUP',
	EDIT_SECTION = 'EDIT_SECTION',
	EDIT_SECTION_INFO = 'EDIT_SECTION_INFO',
	CLOSE_EDIT_SECTION = 'CLOSE_EDIT_SECTION',
	PUBLISH_SECTION = 'PUBLISH_SECTION',
	PUBLISH_SECTION_SUCCESS = 'PUBLISH_SECTION_SUCCESS',
	PUBLISH_SECTION_FAILURE = 'PUBLISH_SECTION_FAILURE',
	UNPUBLISH_SECTION = 'UNPUBLISH_SECTION',
	UNPUBLISH_SECTION_SUCCESS = 'UNPUBLISH_SECTION_SUCCESS',
	UNPUBLISH_SECTION_FAILURE = 'UNPUBLISH_SECTION_FAILURE',
	DRAFT_SECTION = 'DRAFT_SECTION',
	DRAFT_SECTION_SUCCESS = 'DRAFT_SECTION_SUCCESS',
	DRAFT_SECTION_FAILURE = 'DRAFT_SECTION_FAILURE',
	REORDER_SECTIONS = 'REORDER_SECTIONS',
	REORDER_SECTIONS_SUCCESS = 'REORDER_SECTIONS_SUCCESS',
	REORDER_SECTIONS_FAILURE = 'REORDER_SECTIONS_FAILURE'
}

export type ActionType = 'category' | 'submenu' | 'subcategory' | 'file';
type Mode = 'add' | 'edit';

interface AdditionalData {
	additionalData: Record<string, any>;
}
export interface FileInfo {
	id?: string;
	fileId: string;
	fileName: string;
	fileSequence: number;
	isPublished: boolean;
}
export interface SubCategoryInfo extends AdditionalData {
	id?: string;
	name: string;
	sequence: number;
	isPublished: boolean;
	display?: boolean;
	files: Array<FileInfo>;
}
export interface SubMenuInfo {
	id?: string;
	name: string;
	pageTitle: string;
	sequence: number;
	isPublished: boolean;
	subCategories: Array<SubCategoryInfo>;
}
export interface CategoryInfo {
	investorCategoryName: string;
	sequence: number;
	isPublished: boolean;
	subMenus: Array<SubMenuInfo>;
}
export interface SectionContent {
	contentId?: string;
	placeholder: 'InvestorRelations_Details';
	contentTemplate: 'INVESTOR';
	page: 'Investor_Relationship';
	sequence: number;
	isPublished: boolean;
	content: CategoryInfo;
}
export interface Selection {
	category?: any;
	submenu?: any;
	subcategory?: any;
	file?: any;
	sequence?: any;
}

export interface SelectedID {
	groupId: string;
	subMenuId: string;
	subCategoryId: string;
	fileId: string;
}

export interface EditSectionInfoPayload {
	type: ActionType;
	mode: Mode;
	selected?: Selection;
	key: string;
	value: string;
	display?: boolean;
}
export interface EditSectionPayload {
	silent?: boolean;
	selection?: Selection;
	mode?: Mode;
	data?: SectionContent;
}
export interface GetInvestorRelationsDataAction {
	type: typeof SectionActionTypes.GET_INVESTORS_RELATIONS;
	payload?: string;
	meta?: any;
}
export interface GetInvestorRelationsSuccessAction {
	type: typeof SectionActionTypes.GET_INVESTORS_RELATIONS_SUCCESS;
	payload: AjaxResponse;
	meta?: any;
}
export interface GetInvestorRelationsFailureAction {
	type: typeof SectionActionTypes.GET_INVESTORS_RELATIONS_FAILURE;
	payload: AjaxError;
	meta?: any;
}
export interface AddSectionPayload {
	type: ActionType;
	mode: Mode;
	isNew?: boolean;
	selected?: Selection;
	sequence?: number;
}
export interface AddSectionAction {
	type: typeof SectionActionTypes.ADD_SECTION;
	payload?: any;
	meta?: any;
}

export interface PublishSectionAction {
	type: typeof SectionActionTypes.PUBLISH_SECTION;
	payload: SectionContent;
	meta: boolean;
}
export interface PublishSectionSuccessAction {
	type: typeof SectionActionTypes.PUBLISH_SECTION_SUCCESS;
	payload: AjaxResponse;
	meta?: any;
}
export interface PublishSectionFailureAction {
	type: typeof SectionActionTypes.PUBLISH_SECTION_FAILURE;
	payload: AjaxError;
	meta?: any;
}
export interface UnpublishSectionAction {
	type: typeof SectionActionTypes.UNPUBLISH_SECTION;
	payload: SectionContent;
	meta: boolean;
}
export interface UnpublishSectionSuccessAction {
	type: typeof SectionActionTypes.UNPUBLISH_SECTION_SUCCESS;
	payload: AjaxResponse;
	meta?: any;
}
export interface UnpublishSectionFailureAction {
	type: typeof SectionActionTypes.UNPUBLISH_SECTION_FAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface EditSectionAction {
	type: typeof SectionActionTypes.EDIT_SECTION;
	payload: EditSectionPayload;
	meta?: any;
}
export interface EditSectionInfoAction {
	type: typeof SectionActionTypes.EDIT_SECTION_INFO;
	payload: EditSectionInfoPayload;
	meta?: any;
}
export interface CloseEditSectionAction {
	type: typeof SectionActionTypes.CLOSE_EDIT_SECTION;
	payload?: any;
	meta?: any;
}
export interface DraftSectionAction {
	type: typeof SectionActionTypes.DRAFT_SECTION;
	payload: SectionContent;
	meta?: any;
}
export interface DraftSectionSuccessAction {
	type: typeof SectionActionTypes.DRAFT_SECTION_SUCCESS;
	payload: AjaxResponse;
	meta?: any;
}
export interface DraftSectionFailureAction {
	type: typeof SectionActionTypes.DRAFT_SECTION_FAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface SectionState {
	getSectionList: ActionState;
	publishSection: ActionState;
	unpublishSection: ActionState;
	draftSection: ActionState;
	sections: Array<SectionContent>;
	selected: Selection;
	editSection: Partial<SectionContent>;
	editSectionModal: boolean;
	editMode: Mode;
	editActionType: ActionType;
	errorData: Partial<AjaxError>;
	reorderSections: ActionState;
	selectedIds?: SelectedID;
}

export interface SectionActions {
	type: SectionActionTypes;
	payload?: EditSectionPayload | SectionContent | AjaxResponse | AjaxError | any;
	meta?: any;
}

/**
 * Used for the sections
 */
export interface SectionsContent {
	sections: Array<SectionContent>;
	meta: boolean;
	contentTemplate?: string;
}
export interface ReorderSectionsAction {
	type: typeof SectionActionTypes.REORDER_SECTIONS;
	payload: SectionsContent;
	meta: boolean;
}
export interface ReorderSectionsSuccessAction {
	type: typeof SectionActionTypes.REORDER_SECTIONS_SUCCESS;
	payload: AjaxResponse;
	meta?: any;
}
export interface ReorderSectionsFailureAction {
	type: typeof SectionActionTypes.REORDER_SECTIONS_FAILURE;
	payload: AjaxError;
	meta?: any;
}
