import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import set from 'lodash/set';
import assign from 'lodash/assign';
import {
	PlainContent,
	ContentBody,
	PlainContentState,
	PlainContentActions,
	PlainContentActionTypes,
	EditPlainContentInfoPayload
} from './types';
import { ActionState } from '../../constants/api';

const INITIAL_TEMPLATE: ContentBody = {
	body: 0
};
const INITIAL_CONTENT: PlainContent = {
	contentTemplate: 'PLAIN',
	isPublished: false,
	page: 'AboutUs',
	placeholder: 'AboutUs_StoreCount',
	content: INITIAL_TEMPLATE,
	sequence: 0
};
const INITIAL_STATE: PlainContentState = {
	editPlainContentMode: 'add',
	editPlainContent: INITIAL_CONTENT,
	editPlainContentModel: false,
	getPlainContentList: ActionState.NONE,
	publishPlainContent: ActionState.NONE,
	unpublishPlainContent: ActionState.NONE,
	draftPlainContent: ActionState.NONE,
	errorData: {},
	plainContents: []
};
/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const PlainContentReducer = (
	state: PlainContentState = INITIAL_STATE,
	action: PlainContentActions
): PlainContentState => {
	switch (action.type) {
		case PlainContentActionTypes.CLOSEEDITACTION: {
			return {
				...state,
				editPlainContentModel: false
			};
		}
		case PlainContentActionTypes.GETPLAINCONTENTLIST: {
			return {
				...state,
				getPlainContentList: ActionState.LOADING
			};
		}
		case PlainContentActionTypes.GETPLAINCONTENTLISTSUCCESS: {
			const { response } = action.payload as AjaxResponse;
			return {
				...state,
				getPlainContentList: ActionState.SUCCESS,
				plainContents: response || []
			};
		}
		case PlainContentActionTypes.GETPLAINCONTENTLISTFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				getPlainContentList: ActionState.FAILURE,
				errorData: response
			};
		}
		case PlainContentActionTypes.PUBLISHPLAINCONTENT: {
			const edit = action.meta;
			return {
				...state,
				editPlainContentMode: edit ? 'edit' : state.editPlainContentMode,
				publishPlainContent: ActionState.LOADING
			};
		}
		case PlainContentActionTypes.PUBLISHPLAINCONTENTFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				publishPlainContent: ActionState.FAILURE,
				errorData: response
			};
		}
		case PlainContentActionTypes.PUBLISHPLAINCONTENTSUCCESS: {
			return {
				...state,
				publishPlainContent: ActionState.SUCCESS,
				editPlainContentModel: false
			};
		}
		case PlainContentActionTypes.UNPUBLISHPLAINCONTENT: {
			const edit = action.meta;
			return {
				...state,
				editPlainContentMode: edit ? 'edit' : state.editPlainContentMode,
				unpublishPlainContent: ActionState.LOADING
			};
		}
		case PlainContentActionTypes.UNPUBLISHPLAINCONTENTFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				unpublishPlainContent: ActionState.FAILURE,
				errorData: response
			};
		}
		case PlainContentActionTypes.UNPUBLISHPLAINCONTENTSUCCESS: {
			return {
				...state,
				unpublishPlainContent: ActionState.SUCCESS,
				editPlainContentModel: false
			};
		}
		case PlainContentActionTypes.DRAFTPLAINCONTENT: {
			return {
				...state,
				draftPlainContent: ActionState.LOADING
			};
		}
		case PlainContentActionTypes.DRAFTPLAINCONTENTFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				draftPlainContent: ActionState.FAILURE,
				errorData: response
			};
		}
		case PlainContentActionTypes.DRAFTPLAINCONTENTSUCCESS: {
			return {
				...state,
				draftPlainContent: ActionState.SUCCESS,
				editPlainContentModel: false
			};
		}
		case PlainContentActionTypes.ADDPLAINCONTENT: {
			return {
				...state,
				editPlainContentModel: true,
				editPlainContentMode: 'add',
				draftPlainContent: ActionState.NONE,
				publishPlainContent: ActionState.NONE,
				unpublishPlainContent: ActionState.NONE
			};
		}
		case PlainContentActionTypes.EDITPLAINCONTENT: {
			const data = action.payload as PlainContent;
			const info = assign({}, data);
			return {
				...state,
				editPlainContentModel: true,
				editPlainContentMode: 'edit',
				draftPlainContent: ActionState.NONE,
				publishPlainContent: ActionState.NONE,
				unpublishPlainContent: ActionState.NONE,
				editPlainContent: { ...INITIAL_CONTENT, ...info }
			};
		}
		case PlainContentActionTypes.EDITPLAINCONTENTINFO: {
			const { key, value } = action.payload as EditPlainContentInfoPayload;
			const { editPlainContent } = state;
			const data = assign({}, editPlainContent);
			set(data, `content.${key}`, value);
			return {
				...state,
				editPlainContent: { ...data }
			};
		}
		default:
			return state;
	}
};

export default PlainContentReducer;
