import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { ActionState } from '../../constants/api';

export enum PlainContentActionTypes {
	ADDPLAINCONTENT = 'ADDPLAINCONTENT',
	CLOSEEDITACTION = 'CLOSEPLAINCONTENTEDITACTION',
	EDITPLAINCONTENT = 'EDITPLAINCONTENT',
	EDITPLAINCONTENTINFO = 'EDITPLAINCONTENTINFO',
	GETPLAINCONTENTLIST = 'GETPLAINCONTENTLIST',
	GETPLAINCONTENTLISTFAILURE = 'GETPLAINCONTENTLISTFAILURE',
	GETPLAINCONTENTLISTSUCCESS = 'GETPLAINCONTENTLISTSUCCESS',
	PUBLISHPLAINCONTENT = 'PUBLISHPLAINCONTENT',
	PUBLISHPLAINCONTENTSUCCESS = 'PUBLISHPLAINCONTENTSUCCESS',
	PUBLISHPLAINCONTENTFAILURE = 'PUBLISHPLAINCONTENTFAILURE',
	UNPUBLISHPLAINCONTENT = 'UNPUBLISHPLAINCONTENT',
	UNPUBLISHPLAINCONTENTSUCCESS = 'UNPUBLISHPLAINCONTENTSUCCESS',
	UNPUBLISHPLAINCONTENTFAILURE = 'UNPUBLISHPLAINCONTENTFAILURE',
	DRAFTPLAINCONTENT = 'DRAFTPLAINCONTENT',
	DRAFTPLAINCONTENTSUCCESS = 'DRAFTPLAINCONTENTSUCCESS',
	DRAFTPLAINCONTENTFAILURE = 'DRAFTPLAINCONTENTFAILURE'
}
export interface ContentBody {
	body: any;
}
export interface EditPlainContentInfoPayload {
	key: string;
	value: any;
}
export interface PlainContent {
	contentId?: string;
	placeholder: 'AboutUs_StoreCount';
	contentTemplate: 'PLAIN';
	page: 'AboutUs';
	sequence: number;
	isPublished: boolean;
	content: ContentBody;
}

export interface PlainContentState {
	getPlainContentList: ActionState;
	publishPlainContent: ActionState;
	unpublishPlainContent: ActionState;
	draftPlainContent: ActionState;
	plainContents: Array<PlainContent>;
	editPlainContent: PlainContent;
	editPlainContentModel: boolean;
	editPlainContentMode: 'add' | 'edit';
	errorData: Partial<AjaxError>;
}

export interface EditPlainContentInfoAction {
	type: typeof PlainContentActionTypes.EDITPLAINCONTENTINFO;
	payload: EditPlainContentInfoPayload;
	meta?: any;
}

export interface GetPlainContentListAction {
	type: typeof PlainContentActionTypes.GETPLAINCONTENTLIST;
	payload?: string;
	meta?: any;
}

export interface GetPlainContentListSuccessAction {
	type: typeof PlainContentActionTypes.GETPLAINCONTENTLISTSUCCESS;
	payload: AjaxResponse;
	meta?: any;
}

export interface GetPlainContentListFailureAction {
	type: typeof PlainContentActionTypes.GETPLAINCONTENTLISTFAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface AddPlainContentAction {
	type: typeof PlainContentActionTypes.ADDPLAINCONTENT;
	payload?: any;
	meta?: any;
}

export interface EditPlainContentAction {
	type: typeof PlainContentActionTypes.EDITPLAINCONTENT;
	payload: PlainContent;
	meta?: any;
}

export interface PublishPlainContentAction {
	type: typeof PlainContentActionTypes.PUBLISHPLAINCONTENT;
	payload: PlainContent;
	meta: boolean;
}
export interface PublishPlainContentSuccessAction {
	type: typeof PlainContentActionTypes.PUBLISHPLAINCONTENTSUCCESS;
	payload: AjaxResponse;
	meta?: any;
}
export interface PublishPlainContentFailureAction {
	type: typeof PlainContentActionTypes.PUBLISHPLAINCONTENTFAILURE;
	payload: AjaxError;
	meta?: any;
}
export interface UnpublishPlainContentAction {
	type: typeof PlainContentActionTypes.UNPUBLISHPLAINCONTENT;
	payload: PlainContent;
	meta: boolean;
}
export interface UnpublishPlainContentSuccessAction {
	type: typeof PlainContentActionTypes.UNPUBLISHPLAINCONTENTSUCCESS;
	payload: AjaxResponse;
	meta?: any;
}
export interface UnpublishPlainContentFailureAction {
	type: typeof PlainContentActionTypes.UNPUBLISHPLAINCONTENTFAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface DraftPlainContentAction {
	type: typeof PlainContentActionTypes.DRAFTPLAINCONTENT;
	payload: PlainContent;
	meta?: any;
}
export interface DraftPlainContentSuccessAction {
	type: typeof PlainContentActionTypes.DRAFTPLAINCONTENTSUCCESS;
	payload: AjaxResponse;
	meta?: any;
}
export interface DraftPlainContentFailureAction {
	type: typeof PlainContentActionTypes.DRAFTPLAINCONTENTFAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface ClosePlainContentEditAction {
	type: typeof PlainContentActionTypes.CLOSEEDITACTION;
	payload?: any;
	meta?: any;
}

export interface PlainContentActions {
	type: PlainContentActionTypes;
	payload?: EditPlainContentInfoPayload | PlainContent | AjaxResponse | AjaxError | any;
	meta?: any;
}
