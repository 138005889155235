import { combineReducers } from 'redux';
import StoreReducer from '../redux/Stores/reducers';
import InvestorRelationsReducer from '../redux/InvestorRelations/reducers';
import PlainContentReducer from '../redux/PlainContent/reducers';
import AuthReducer from '../redux/Login/reducers';
import FileReducer from '../redux/File/reducers';
import RichContentReducer from '../redux/RichContent/reducers';
import PreviewFileReducer from '../redux/PreviewFile/reducers';

const Reducer = combineReducers({
	store: StoreReducer,
	section: InvestorRelationsReducer,
	plainContent: PlainContentReducer,
	auth: AuthReducer,
	richContent: RichContentReducer,
	filePreview: PreviewFileReducer,
	file: FileReducer
});
export type RootState = ReturnType<typeof Reducer>;
export default Reducer;
