import { combineEpics } from 'redux-observable';
import { DraftStore, GetStore, PublishStore, UnpublishStore } from '../redux/Stores/epic';
import GetInvestorRelations, {
	DraftSectionEpic,
	PublishSectionEpic,
	UnpublishSectionEpic
} from '../redux/InvestorRelations/epic';

import {
	DraftPlainContent,
	GetPlainContent,
	PublishPlainContent,
	UnpublishPlainContent
} from '../redux/PlainContent/epic';
import { LoginEpic, LogoutEpic } from '../redux/Login/epic';
import { FileUploadEpic } from '../redux/File/epic';
import { ContentActionEpic, GetContentEpic } from '../redux/RichContent/epic';
import { GetFileEpic } from '../redux/PreviewFile/epic';

export default combineEpics(
	DraftStore,
	GetStore,
	PublishStore,
	UnpublishStore,
	GetInvestorRelations,
	DraftSectionEpic,
	GetFileEpic,
	PublishSectionEpic,
	UnpublishSectionEpic,
	DraftPlainContent,
	GetPlainContent,
	PublishPlainContent,
	UnpublishPlainContent,
	LoginEpic,
	FileUploadEpic,
	ContentActionEpic,
	GetContentEpic,
	LogoutEpic
);
