import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { map, switchMap, catchError, merge, takeUntil } from 'rxjs/operators';
import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { of } from 'rxjs/internal/observable/of';
import { Subject } from 'rxjs/internal/Subject';
import { FileActions, FileActionTypes, UploadPayload } from './types';
import { UploadFileProgress, UploadFileFailure, UploadFileSuccess } from './actions';
import { RootState } from '../../reducers';
import { secureRequest } from '../../utils/functions';
import { APIError } from '../Login/actions';

const {
	REACT_APP_API_URL = '',
	REACT_APP_CONTENT_BASE_URL = '',
	REACT_APP_FILE_API = ''
} = process.env;
const FILE_API = REACT_APP_API_URL + REACT_APP_CONTENT_BASE_URL + REACT_APP_FILE_API;

export const FileUploadEpic = (
	action$: ActionsObservable<FileActions>,
	store$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(FileActionTypes.UPLOADFILE),
		switchMap((action) => {
			const ActionPayload = action.payload as UploadPayload;
			const data = new FormData();
			data.append('file', ActionPayload.file, ActionPayload.file.name);
			const progressSubscriber: any = new Subject<any>();
			const request = secureRequest(
				{
					method: 'POST',
					url: FILE_API,
					body: data,
					responseType: 'text',
					async: true,
					crossDomain: true,
					progressSubscriber
				},
				store$
			).pipe(
				map((response: AjaxResponse) => UploadFileSuccess(response, action)),
				catchError((error: AjaxError) =>
					of(UploadFileFailure(error, action), APIError(error, action))
				),
				takeUntil(action$.pipe(ofType(FileActionTypes.CLEARFILEUPLOAD)))
			);
			return progressSubscriber.pipe(
				map((e: any) => ({ percentage: (e.loaded / e.total) * 100 })),
				map((percentage) => UploadFileProgress(percentage)),
				catchError((error: AjaxError) =>
					of(UploadFileFailure(error, action), APIError(error, action))
				),
				merge(request)
			);
		})
	);

export const FileEpic = FileUploadEpic;
