export enum ActionState {
	NONE = 'NONE',
	LOADING = 'LOADING',
	UPLOADING = 'UPLOADING',
	FAILURE = 'FAILURE',
	SUCCESS = 'SUCCESS',
	RETRYING = 'RETRYING'
}
export enum ActionsStatus {
	NONE = 'NONE',
	LOADING = 'LOADING',
	FAILURE = 'FAILURE',
	SUCCESS = 'SUCCESS',
	RETRYING = 'RETRYING'
}
export enum FormError {
	WARN = 'WARN',
	INFO = 'INFO',
	NONE = 'NONE',
	ERROR = 'ERROR'
}

export const actionStatus = ActionState;

export enum OrderStatus {
	NOTSTARTED = 'NOTSTARTED',
	INPROGRESS = 'INPROGRESS',
	COMPLETED = 'COMPLETED'
}
