import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { FileState, FileActions, FileActionTypes } from './types';
import { ActionState } from '../../constants/api';

const INITIAL_STATE: FileState = {
	details: '',
	percent: 0,
	uploadFile: ActionState.NONE,
	errorData: {}
};
/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const FileReducer = (state: FileState = INITIAL_STATE, action: FileActions): FileState => {
	switch (action.type) {
		case FileActionTypes.UPLOADFILE: {
			return {
				...state,
				...INITIAL_STATE,
				uploadFile: ActionState.LOADING
			};
		}
		case FileActionTypes.CLEARFILEUPLOAD: {
			return {
				...state,
				...INITIAL_STATE
			};
		}
		case FileActionTypes.UPLOADFILESUCCESS: {
			const { response } = action.payload as AjaxResponse;
			return {
				...state,
				uploadFile: ActionState.SUCCESS,
				details: response,
				percent: 100
			};
		}
		case FileActionTypes.UPLOADFILEFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				uploadFile: ActionState.FAILURE,
				errorData: response
			};
		}
		case FileActionTypes.UPLOADFILEPROGRESS: {
			const { percentage } = action.payload;
			return {
				...state,
				uploadFile: ActionState.UPLOADING,
				percent: percentage
			};
		}
		default:
			return state;
	}
};

export default FileReducer;
