import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import {
	PlainContentActionTypes,
	EditPlainContentInfoPayload,
	EditPlainContentInfoAction,
	GetPlainContentListAction,
	GetPlainContentListSuccessAction,
	GetPlainContentListFailureAction,
	PlainContent,
	DraftPlainContentAction,
	EditPlainContentAction,
	AddPlainContentAction,
	PublishPlainContentAction,
	PublishPlainContentSuccessAction,
	PublishPlainContentFailureAction,
	UnpublishPlainContentAction,
	UnpublishPlainContentSuccessAction,
	UnpublishPlainContentFailureAction,
	DraftPlainContentSuccessAction,
	DraftPlainContentFailureAction,
	ClosePlainContentEditAction
} from './types';

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const EditPlainContentInfo = (
	payload: EditPlainContentInfoPayload,
	meta?: any
): EditPlainContentInfoAction => {
	return {
		type: PlainContentActionTypes.EDITPLAINCONTENTINFO,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetPlainContentList = (payload?: any, meta?: any): GetPlainContentListAction => {
	return {
		type: PlainContentActionTypes.GETPLAINCONTENTLIST,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetPlainContentListSuccess = (
	payload: AjaxResponse,
	meta?: any
): GetPlainContentListSuccessAction => {
	return {
		type: PlainContentActionTypes.GETPLAINCONTENTLISTSUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetPlainContentListFailure = (
	payload: AjaxError,
	meta?: any
): GetPlainContentListFailureAction => {
	return {
		type: PlainContentActionTypes.GETPLAINCONTENTLISTFAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const EditPlainContent = (payload: PlainContent, meta?: any): EditPlainContentAction => {
	return {
		type: PlainContentActionTypes.EDITPLAINCONTENT,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const AddPlainContent = (payload?: any, meta?: any): AddPlainContentAction => {
	return {
		type: PlainContentActionTypes.ADDPLAINCONTENT,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const PublishPlainContent = (
	payload: PlainContent,
	meta = false
): PublishPlainContentAction => {
	return {
		type: PlainContentActionTypes.PUBLISHPLAINCONTENT,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const PublishPlainContentSuccess = (
	payload: AjaxResponse,
	meta?: any
): PublishPlainContentSuccessAction => {
	return {
		type: PlainContentActionTypes.PUBLISHPLAINCONTENTSUCCESS,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const PublishPlainContentFailure = (
	payload: AjaxError,
	meta?: any
): PublishPlainContentFailureAction => {
	return {
		type: PlainContentActionTypes.PUBLISHPLAINCONTENTFAILURE,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UnPublishPlainContent = (
	payload: PlainContent,
	meta = false
): UnpublishPlainContentAction => {
	return {
		type: PlainContentActionTypes.UNPUBLISHPLAINCONTENT,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UnPublishPlainContentSuccess = (
	payload: AjaxResponse,
	meta?: any
): UnpublishPlainContentSuccessAction => {
	return {
		type: PlainContentActionTypes.UNPUBLISHPLAINCONTENTSUCCESS,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UnPublishPlainContentFailure = (
	payload: AjaxError,
	meta?: any
): UnpublishPlainContentFailureAction => {
	return {
		type: PlainContentActionTypes.UNPUBLISHPLAINCONTENTFAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const DraftPlainContent = (payload: PlainContent, meta?: any): DraftPlainContentAction => {
	return {
		type: PlainContentActionTypes.DRAFTPLAINCONTENT,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const DraftPlainContentSuccess = (
	payload: AjaxResponse,
	meta?: any
): DraftPlainContentSuccessAction => {
	return {
		type: PlainContentActionTypes.DRAFTPLAINCONTENTSUCCESS,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const DraftPlainContentFailure = (
	payload: AjaxError,
	meta?: any
): DraftPlainContentFailureAction => {
	return {
		type: PlainContentActionTypes.DRAFTPLAINCONTENTFAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const ClosePlainContentEdit = (payload: any, meta?: any): ClosePlainContentEditAction => {
	return {
		type: PlainContentActionTypes.CLOSEEDITACTION,
		payload,
		meta
	};
};
