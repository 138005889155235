import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { map, mergeMap, catchError, merge, takeUntil } from 'rxjs/operators';
import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { of } from 'rxjs/internal/observable/of';
import { Subject } from 'rxjs/internal/Subject';
import { FileActions, FILE_UPLOAD, FileUpload, GET_FILE, GETFILE } from './types';
import { RootState } from '../../reducers';
import { secureRequest } from '../../utils/functions';

const {
	REACT_APP_API_URL = '',
	REACT_APP_CONTENT_PRIVATE_BASE_URL = '',
	REACT_APP_FILE_API = '',
	REACT_APP_AUDIO_API = ''
} = process.env;
const CONTENT_FILE_API =
	REACT_APP_API_URL + REACT_APP_CONTENT_PRIVATE_BASE_URL + REACT_APP_FILE_API;
const CONTENT_AUDIO_API =
	REACT_APP_API_URL + REACT_APP_CONTENT_PRIVATE_BASE_URL + REACT_APP_AUDIO_API;
export const FileUploadEpic = (
	action$: ActionsObservable<FileActions>,
	store$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(FILE_UPLOAD.name),
		mergeMap((action) => {
			const ActionPayload = action.payload as FileUpload;
			const data = new FormData();
			data.append('file', ActionPayload.file);
			const progressSubscriber: any = new Subject<any>();
			const request = secureRequest(
				{
					method: 'POST',
					url: CONTENT_FILE_API,
					body: data,
					responseType: 'text',
					async: true,
					crossDomain: true,
					progressSubscriber
				},
				store$
			).pipe(
				map((response: AjaxResponse) => FILE_UPLOAD.successAction(response, action)),
				catchError((error: AjaxError) => of(FILE_UPLOAD.failureAction(error, action))),
				takeUntil(action$.pipe(ofType(FILE_UPLOAD.cancel)))
			);
			return progressSubscriber.pipe(
				map((e: any) => ({ percentage: (e.loaded / e.total) * 100 })),
				map((percentage) => FILE_UPLOAD.progressAction(percentage, action)),
				catchError((error: AjaxError) => of(FILE_UPLOAD.failureAction(error, action))),
				merge(request)
			);
		})
	);

export const FileEpic = FileUploadEpic;

export const GetFileEpic = (
	action$: ActionsObservable<FileActions>,
	store$: StateObservable<RootState>
) =>
	action$.pipe(
		ofType(GET_FILE.name),
		mergeMap((action) => {
			const ActionPayload = action.payload as GETFILE;
			let url = CONTENT_FILE_API;
			if (ActionPayload.fileType && ActionPayload.fileType === 'audio/mpeg') {
				url = CONTENT_AUDIO_API;
			}
			return secureRequest(
				{
					method: 'GET',
					url: `${url}/${ActionPayload.contentId}/${ActionPayload.fileId}/${ActionPayload.fileName}`,
					async: true,
					crossDomain: true,
					responseType: 'blob'
				},
				store$
			).pipe(
				map((response: AjaxResponse) => GET_FILE.successAction(response, action)),
				catchError((error: AjaxError) => of(GET_FILE.failureAction(error, action))),
				takeUntil(action$.pipe(ofType(GET_FILE.cancel)))
			);
		})
	);
