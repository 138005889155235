import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import set from 'lodash/set';
import assign from 'lodash/assign';
import clone from 'lodash/cloneDeep';
import {
	StoreContent,
	StoreInfo,
	StoreState,
	StoreActions,
	StoreAction,
	EditStoreInfoPayload
} from './types';
import { ActionState } from '../../constants/api';

const INITIAL_TEMPLATE: StoreInfo = {
	body: '',
	additionalData: {},
	files: [],
	subTitle: '',
	title: ''
};
const INITIAL_CONTENT: StoreContent = {
	contentTemplate: 'RICH',
	isPublished: false,
	page: 'Home',
	placeholder: 'Home_NewStore',
	content: INITIAL_TEMPLATE,
	sequence: 0
};
const INITIAL_STATE: StoreState = {
	actiontype: 'add',
	editStore: INITIAL_CONTENT,
	editStoreModel: false,
	getStoreList: ActionState.NONE,
	publishStore: ActionState.NONE,
	unpublishStore: ActionState.NONE,
	draftStore: ActionState.NONE,
	errorData: {},
	stores: []
};
/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const StoreReducer = (state: StoreState = INITIAL_STATE, action: StoreActions): StoreState => {
	switch (action.type) {
		case StoreAction.CLOSEEDITACTION: {
			return {
				...state,
				editStoreModel: false,
				editStore: { ...clone(INITIAL_CONTENT) }
			};
		}
		case StoreAction.GETSTORELIST: {
			return {
				...state,
				getStoreList: ActionState.LOADING
			};
		}
		case StoreAction.GETSTORELISTSUCCESS: {
			const { response } = action.payload as AjaxResponse;
			return {
				...state,
				getStoreList: ActionState.SUCCESS,
				stores: response
			};
		}
		case StoreAction.GETSTORELISTFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				getStoreList: ActionState.FAILURE,
				errorData: response
			};
		}
		case StoreAction.PUBLISHSTORE: {
			const edit = action.meta;
			return {
				...state,
				actiontype: edit ? 'edit' : state.actiontype,
				publishStore: ActionState.LOADING
			};
		}
		case StoreAction.PUBLISHSTOREFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				publishStore: ActionState.FAILURE,
				errorData: response
			};
		}
		case StoreAction.PUBLISHSTORESUCCESS: {
			return {
				...state,
				publishStore: ActionState.SUCCESS,
				editStoreModel: false,
				editStore: { ...clone(INITIAL_CONTENT) }
			};
		}
		case StoreAction.UNPUBLISHSTORE: {
			const edit = action.meta;
			return {
				...state,
				actiontype: edit ? 'edit' : state.actiontype,
				unpublishStore: ActionState.LOADING
			};
		}
		case StoreAction.UNPUBLISHSTOREFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				unpublishStore: ActionState.FAILURE,
				errorData: response
			};
		}
		case StoreAction.UNPUBLISHSTORESUCCESS: {
			return {
				...state,
				unpublishStore: ActionState.SUCCESS,
				editStoreModel: false,
				editStore: { ...clone(INITIAL_CONTENT) }
			};
		}
		case StoreAction.DRAFTSTORE: {
			return {
				...state,
				draftStore: ActionState.LOADING
			};
		}
		case StoreAction.DRAFTSTOREFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				draftStore: ActionState.FAILURE,
				errorData: response
			};
		}
		case StoreAction.DRAFTSTORESUCCESS: {
			return {
				...state,
				draftStore: ActionState.SUCCESS,
				editStoreModel: false,
				editStore: { ...clone(INITIAL_CONTENT) }
			};
		}
		case StoreAction.ADDSTORE: {
			return {
				...state,
				editStoreModel: true,
				actiontype: 'add',
				draftStore: ActionState.NONE,
				publishStore: ActionState.NONE,
				unpublishStore: ActionState.NONE,
				editStore: { ...clone(INITIAL_CONTENT) }
			};
		}
		case StoreAction.EDITSTORE: {
			const data = action.payload as StoreContent;
			const info = assign({}, data);
			return {
				...state,
				editStoreModel: true,
				actiontype: 'edit',
				draftStore: ActionState.NONE,
				publishStore: ActionState.NONE,
				unpublishStore: ActionState.NONE,
				editStore: { ...INITIAL_CONTENT, ...info }
			};
		}
		case StoreAction.EDITSTOREINFO: {
			const { key, value } = action.payload as EditStoreInfoPayload;
			const { editStore } = state;
			const data = assign({}, editStore);
			set(data, `content.${key}`, value);
			return {
				...state,
				editStore: { ...data }
			};
		}
		default:
			return state;
	}
};

export default StoreReducer;
